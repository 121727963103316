import { configureStore } from '@reduxjs/toolkit';
import formReducer from './taxItemFormSlice';
import itemsReducer from './taxItemSlice';

export const store = configureStore({
  reducer: {
    form: formReducer,
    items: itemsReducer
  }
});
