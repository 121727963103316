export default function apiMapper(input) {
    return {
        id: input.id,
        name: input.name,
        frequencyString: input.frequency_string,
        hasAllFrequencies: input.has_all_frequencies,
        inspectionsCount: input.inspections_count,
        frequencyIds: input.frequency_ids
    };
}
