import React from 'react';
import PropTypes from 'prop-types';

function ContactCell({ colSpan, dataItem, field, className, ariaColumnIndex, style }) {
  return (
    <td
      colSpan={colSpan}
      className={className}
      role="gridcell"
      aria-colindex={ariaColumnIndex}
      aria-selected="false"
      style={style}>
      {dataItem.contactName}
      <br />
      {dataItem.contactEmail}
    </td>
  );
}

ContactCell.propTypes = {
  colSpan: PropTypes.number,
  dataItem: PropTypes.shape({
    path: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

export default ContactCell;
