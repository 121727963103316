import { useEffect, useRef } from 'react';
import axios from 'axios';

const abortableAxios = () => {
  const controllerRef = useRef(null);

  useEffect(() => {
    controllerRef.current = new AbortController();
    const controller = controllerRef.current;

    return () => {
      controller.abort();
    };
  }, []);

  const request = async (config) => {
    try {
      return await axios({ ...config, signal: controllerRef.current.signal });
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw error;
      }
    }
  };

  return { request };
};

export default abortableAxios;
