import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import KendoActionsCell from '@components/Kendo/ActionsCell';
import { Dialog } from '@progress/kendo-react-dialogs';
import I18n, { translate } from '@utils/i18n';
import {
  subcontractorPath,
  activateToggleSubcontractorPath,
  editSubcontractorPath,
  resendSubcontractorInvitePath
} from 'routes';
import { Tooltip } from '@progress/kendo-react-tooltip';
import api from '@utils/axios';

const namespace = 'features.subcontractors.index.actions_cell';

function ActionsCell({ colSpan, dataItem, field, className, ariaColumnIndex, style }) {
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const onResend = useCallback(() => {
    api.post(resendSubcontractorInvitePath(dataItem.id)).then(() => window.location.reload());
  }, []);

  const onConfirmCancel = useCallback(() => {
    api.delete(subcontractorPath({ id: dataItem.id })).then(() => window.location.reload());
  }, []);

  const onCancel = () => toggleDeleteDialog();

  const toggleDeleteDialog = () => setDeleteDialogVisible((prev) => !prev);

  const actionsList = useMemo(() => {
    const list = [];
    if (dataItem.canEdit) {
      list.push({
        title: I18n.t('generic.edit_details'),
        faClass: 'fa-pen-to-square',
        link: editSubcontractorPath(dataItem.id)
      });
    }

    if (dataItem.canResend) {
      list.push({
        title: I18n.t('generic.re_send'),
        faClass: 'fa-paper-plane',
        onClick: onResend
      });
    }

    if (dataItem.canCancel) {
      list.push({
        title: I18n.t('generic.cancel_invite'),
        faClass: 'fa-ban red',
        onClick: onCancel
      });
    }

    return list;
  }, []);

  const toggleTitle = useMemo(() => {
    if (!dataItem.canActivateToggle) return translate('cannot_deactivate', { namespace });

    if (dataItem.active) return translate('click_to_deactivate', { namespace });

    return translate('click_to_activate', { namespace });
  }, []);

  const toggleActive = () => {
    api
      .post(activateToggleSubcontractorPath(dataItem.id))
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  if (actionsList.length === 0)
    return (
      <td
        colSpan={colSpan}
        className={className}
        role="gridcell"
        aria-colindex={ariaColumnIndex}
        aria-selected="false"
        style={style}>
        <button
          type="button"
          onClick={toggleActive}
          disabled={!dataItem.canActivateToggle}
          className="qmb-control--icon--toggle--active"
          style={{ marginRight: '1rem' }}>
          <Tooltip anchorElement="target" position="top">
            <i className={`fa-light ${dataItem.active ? 'fa-eye' : 'fa-eye-slash'}`} title={toggleTitle} />
          </Tooltip>
        </button>
      </td>
    );

  return (
    <>
      <KendoActionsCell
        colSpan={colSpan}
        dataItem={dataItem}
        field={field}
        className={className}
        ariaColumnIndex={ariaColumnIndex}
        style={style}
        actionsList={actionsList}>
        <button
          type="button"
          onClick={toggleActive}
          disabled={!dataItem.canActivateToggle}
          className="qmb-control--icon--toggle--active"
          style={{ marginRight: '1rem' }}>
          <Tooltip anchorElement="target" position="top">
            <i className={`fa-light ${dataItem.active ? 'fa-eye' : 'fa-eye-slash'}`} title={toggleTitle} />
          </Tooltip>
        </button>
      </KendoActionsCell>

      {deleteDialogVisible && (
        <Dialog title={translate('confirm_cancel', { namespace })} className="qmb-dialog" onClose={toggleDeleteDialog}>
          <p>{translate('cancel_invite', { namespace })}</p>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button type="button" className="qmb-button" onClick={toggleDeleteDialog}>
              {I18n.t('generic.cancel')}
            </button>
            <button type="button" className="qmb-button--submit" onClick={onConfirmCancel}>
              {I18n.t('generic.ok')}
            </button>
          </div>
        </Dialog>
      )}
    </>
  );
}

ActionsCell.propTypes = {
  ariaColumnIndex: PropTypes.number.isRequired,
  style: PropTypes.object,
  field: PropTypes.string.isRequired,
  className: PropTypes.string,
  colSpan: PropTypes.number.isRequired,
  dataItem: PropTypes.shape({
    isResolved: PropTypes.bool,
    photos: PropTypes.arrayOf(PropTypes.object),
    path: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

ActionsCell.defaultProps = {
  style: null,
  className: ''
};

export default ActionsCell;
