import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@utils/i18n';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Brushaway from '../../../../components/Brushaway';
import TaxItemForm from './TaxItemForm';
import { updateForm } from './Redux/taxItemFormSlice';
import { updateItems } from './Redux/taxItemSlice';
import abortableAxios from './hooks/abortableAxios';
import EditRateInformational from './EditRateInformational';
import formatErrors from './helpers/formatErrors';

function EditTaxRate({ taxItem, namespace }) {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.form.formData);
  const { request } = abortableAxios();

  const handleSubmitForm = async () => {
    try {
      const response = await request({
        method: 'PATCH',
        url: `/tax_rates/${taxItem.id}`,
        data: { tax_item: formData }
      });
      dispatch(updateItems(response.data.tax_items));
      toast.success(translate('success_edit_tax_rate', { namespace }));
      return true;
    } catch (error) {
      toast.error(formatErrors(error?.response?.data?.errors) ?? translate('error_edit_tax_rate', { namespace }));
      return false;
    }
  };

  const setFormData = () => {
    if (taxItem !== null) {
      dispatch(
        updateForm({
          name: taxItem.name,
          rate: taxItem.rate,
          tax_rate_ids: taxItem.all_tax_rates?.map((rate) => rate.id) ?? [],
          tax_agency: taxItem.tax_agency,
          tax_rates_attributes: []
        })
      );
    }
  };

  const anchor = ({ onClick }) => (
    <a
      href="#"
      onClick={() => {
        setFormData();
        onClick();
      }}>
      {taxItem.name}
    </a>
  );

  return (
    <Brushaway
      anchor={anchor}
      title={translate('edit_tax_rate', { namespace })}
      isForm
      onSubmit={handleSubmitForm}>
      <EditRateInformational namespace={namespace} />
      <TaxItemForm taxItem={taxItem} />
    </Brushaway>
  );
}

EditTaxRate.propTypes = {
  taxItem: PropTypes.object.isRequired,
  namespace: PropTypes.string.isRequired
};

export default EditTaxRate;
