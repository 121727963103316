import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useDateManager from '@hooks/use-date-manager.hook';
import { isVisit } from '@utils/typeGuards/scheduler-event-typeguard';
import { dateClient } from '@utils/date-client';
import useGetAssignees from '@hooks/use-get-assignees';
import useGreedyState from '@hooks/use-greedy-state.hook';
import { GlobalDragContext } from './GlobalDragContext';
import useGetSchedulerTimeOffs from '../Scheduler/scheduler-only-hooks/use-get-scheduler-time-offs';
import useGetSchedulerVisits from '../Scheduler/scheduler-only-hooks/use-get-scheduler-visits';
import useEventManager from '../Scheduler/scheduler-only-hooks/use-event-manager';
import { ScheduleAndDispatchContext } from './ScheduleAndDispatchContext';
import { TimeZoneContext } from '../../../contexts/timezone-context';
import useGetSchedulerHolidays from '../Scheduler/scheduler-only-hooks/use-get-scheduler-holidays';
export const SchedulerContext = createContext(undefined);
export function SchedulerContextProvider(params) {
    const { timezone } = useContext(TimeZoneContext);
    const { modifiedEventWatch } = useContext(ScheduleAndDispatchContext);
    const { activeGlobalDragItem, startDragging, stopDragging } = useContext(GlobalDragContext);
    const { activeDate, incrementDate, decrementDate, setDate } = useDateManager();
    const { data: teamId, setData: setTeamId } = useGreedyState(params.teamId);
    const [assigneesOnMap, setAssigneesOnMap] = useState('all');
    const activeEvent = useRef(undefined);
    const activeEventOriginalState = useRef(undefined);
    // Fetch data
    const { assignees, loading: assigneesLoading } = useGetAssignees({ teamId });
    const { timeOffs, timeOffsLoading } = useGetSchedulerTimeOffs(assignees, activeDate);
    const { visits, visitsLoading } = useGetSchedulerVisits(assignees, activeDate);
    const { holidays, holidaysLoading } = useGetSchedulerHolidays(activeDate);
    // Manage Events
    const { loading: eventManagerLoading, groupedEvents, actions: { moveEvent, reassignEvent, replaceEvent, adjustEventTime, addEvent, removeEvent } } = useEventManager(visits, timeOffs);
    const schedulerLoading = assigneesLoading || timeOffsLoading || visitsLoading || eventManagerLoading;
    useEffect(() => {
        if (modifiedEventWatch) {
            replaceEvent(modifiedEventWatch);
        }
    }, [modifiedEventWatch]);
    const handleSchedulerExit = useCallback((e) => {
        if (activeEvent.current && activeEventOriginalState.current) {
            startDragging(activeEventOriginalState.current, () => {
                const event = activeEventOriginalState.current;
                if (event) {
                    activeEvent.current = undefined;
                    addEvent(event);
                }
                stopDragging();
            });
            removeEvent(activeEvent.current.key);
        }
    }, []);
    const handleSchedulerEnter = useCallback((e) => {
        const inboundEvent = activeGlobalDragItem.current;
        if (inboundEvent) {
            stopDragging();
            activeEvent.current = inboundEvent;
            activeEventOriginalState.current = Object.assign({}, inboundEvent);
            addEvent(inboundEvent);
            moveEvent(inboundEvent.key, currentMouseTime.current);
        }
    }, []);
    const setActiveSchedulerEvent = useCallback((event) => {
        if (event && isVisit(event)) {
            activeEvent.current = event;
            activeEventOriginalState.current = Object.assign({}, event); // Set by value, not ref, allowing revert
        }
        else {
            activeEvent.current = undefined;
            activeEventOriginalState.current = undefined;
        }
    }, []);
    const currentMouseTime = useRef(dateClient().tz(timezone));
    useEffect(() => {
        const SetCurrentMouseTime = (e) => {
            const timeHeader = document.getElementById(`scheduler-time-header`);
            const clickX = e.clientX - timeHeader.getBoundingClientRect().left;
            const percentage = (clickX / timeHeader.clientWidth) * 100;
            const minutes = (percentage / 100) * 1440;
            const hours = Math.floor(minutes / 60);
            const minutesRemainder = Math.floor(minutes % 60);
            currentMouseTime.current = activeDate.set('hour', hours).set('minute', minutesRemainder);
        };
        document.addEventListener('mousemove', SetCurrentMouseTime);
        return () => {
            document.removeEventListener('mousemove', SetCurrentMouseTime);
        };
    }, [activeDate]);
    const setAssigneeShowOnMap = useCallback((assignee, showOnMap) => {
        setAssigneesOnMap((prevState) => {
            let newState = prevState === 'all' ? (assignees || []).map((x) => x.key) : [...prevState];
            if (!showOnMap) {
                newState = newState.filter((x) => x !== assignee.key);
            }
            else if (prevState !== 'all')
                newState.push(assignee.key);
            return newState;
        });
    }, [assignees]);
    const contextData = useMemo(() => {
        const value = {
            loading: schedulerLoading,
            swimlaneAssignees: assignees || [],
            eventsGroupedByAssignee: groupedEvents,
            activeDate,
            incrementCurrentDate: incrementDate,
            decrementCurrentDate: decrementDate,
            setCurrentDate: setDate,
            moveEvent,
            reassignEvent,
            replaceEvent,
            adjustEventTime,
            handleSchedulerExit,
            handleSchedulerEnter,
            activeEvent,
            activeEventOriginalState,
            currentMouseTime,
            setActiveSchedulerEvent,
            assigneesOnMap,
            setAssigneeShowOnMap,
            holidays
        };
        return value;
    }, [assignees, groupedEvents, schedulerLoading, schedulerLoading, activeDate, assigneesOnMap, holidays]);
    return React.createElement(SchedulerContext.Provider, { value: contextData }, params.children);
}
