var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';
import api from '@utils/axios';
import { translate } from '@utils/i18n';
import { namespace } from './constants';
import { externalFormPath, externalFormsPath } from '../../../../routes';
import apiMapper from './apiMapper';
function useExternalForms({ searchText, frequencies }) {
    const [forms, setForms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const getExternalForms = (text) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        const res = yield api.get(externalFormsPath({ format: 'json', search: text }));
        // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
        setForms(res.data.external_forms.map(apiMapper));
        setLoading(false);
    });
    const updateExternalForm = (_a) => __awaiter(this, [_a], void 0, function* ({ id, hasAllFrequencies, frequencyIds }) {
        try {
            setLoadingEdit(true);
            yield api.patch(`${externalFormPath(id)}.json`, {
                external_form: {
                    has_all_frequencies: hasAllFrequencies,
                    frequency_ids: frequencyIds
                }
            });
            const frequencyString = hasAllFrequencies
                ? translate('all', { namespace })
                : frequencyIds.map((fid) => { var _a; return (_a = frequencies.find((freq) => freq.value === fid)) === null || _a === void 0 ? void 0 : _a.label; }).join(', ');
            setForms((prev) => prev.map((f) => f.id === id
                ? Object.assign(Object.assign({}, f), { hasAllFrequencies,
                    frequencyIds, frequencyString: frequencyString || '--' }) : f));
            setLoadingEdit(false);
            return true;
        }
        catch (e) {
            setLoadingEdit(false);
            return false;
        }
    });
    useEffect(() => {
        void getExternalForms(searchText);
    }, [searchText]);
    return {
        forms,
        loading,
        updateExternalForm,
        loadingEdit
    };
}
export default useExternalForms;
