import React, { createContext, useState, useMemo, useEffect } from 'react';
const actionNotProvided = () => {
    console.warn('Action not provided to context');
};
const defaultContext = {
    isOpen: false,
    buttonsDisabled: false,
    toggleBrushaway: actionNotProvided,
    closeBrushaway: actionNotProvided,
    setButtonsDisabled: actionNotProvided
};
export const BrushawayContext = createContext(defaultContext);
export function BrushawayProvider({ children, controlledOpen, onClose }) {
    const [isOpen, setIsOpen] = useState(Boolean(controlledOpen));
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const toggleBrushaway = () => {
        if (isOpen && controlledOpen !== undefined) {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
        setIsOpen((prev) => !prev);
    };
    const closeBrushaway = () => {
        setIsOpen(false);
        if (controlledOpen !== undefined) {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    };
    useEffect(() => {
        if (controlledOpen !== undefined) {
            setIsOpen(controlledOpen);
        }
    }, [controlledOpen]);
    const contextValue = useMemo(() => ({
        isOpen,
        buttonsDisabled,
        toggleBrushaway,
        closeBrushaway,
        setButtonsDisabled
    }), [isOpen, buttonsDisabled]);
    return React.createElement(BrushawayContext.Provider, { value: contextValue }, children);
}
BrushawayProvider.defaultProps = {
    controlledOpen: undefined,
    onClose: undefined
};
