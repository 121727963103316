import React from 'react';
import PropTypes from 'prop-types';
import I18n, { translate } from '@utils/i18n';

const namespace = 'features.settings.price_lists.index';

function ActionButtons({ priceList, onSwitch, onDuplicate }) {
  // const title = priceList.is_default ? translate('default_title', { namespace }) : '';

  return (
    <span>
      {/* {priceList.active && (
        <button
          disabled={priceList.is_default}
          type="button"
          className="qmb-control--icon"
          onClick={onSwitch}
          title={title}>
          <i className="fa-light fa-eye" />
        </button>
      )}
      {!priceList.active && (
        <button type="button" className="qmb-control--icon" onClick={onSwitch}>
          <i className="fa-light fa-eye-slash" />
        </button> 
      )} */}
      <button
        type="button"
        className="qmb-control--icon"
        title={translate('duplicate', { namespace })}
        onClick={onDuplicate}>
        <i className="fa-light fa-copy" />
      </button>
    </span>
  );
}

ActionButtons.propTypes = {
  priceList: PropTypes.array.isRequired,
  onSwitch: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired
};

export default ActionButtons;
