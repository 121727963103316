import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';

function Index({ onConfirm, namespace, anchor }) {
  const [visible, setVisible] = useState(false);

  const hideModal = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const submitModal = () => {
    onConfirm();
    hideModal();
  };

  return (
    <>
      {anchor && anchor({ onClick: showModal })}

      {visible && (
        <div
          id="confirmationModal"
          className="modal k-window k-dialog qmb-dialog"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="k-window-titlebar k-dialog-titlebar">
              <h4 className="modal-title k-window-title k-dialog-title">
                {translate('archive_modal_title', { namespace })}
              </h4>
              <button
                type="button"
                className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action k-dialog-titlebar-action"
                aria-label="Close">
                <span aria-hidden="true" onClick={hideModal}>
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-content k-window-content k-dialog-content">
              <div className="modal-body">
                {translate('archive_modal_body', { namespace })}
                <br />
                <br />
                {translate('archive_modal_body_2', { namespace })}
              </div>
              <div className="modal__footer pb-2">
                <button type="button" className="qmb-button" onClick={hideModal}>
                  {translate('cancel', { namespace })}
                </button>
                <button type="button" className="qmb-button--submit" onClick={submitModal}>
                  {translate('confirm', { namespace })}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Index.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  anchor: PropTypes.func.isRequired
};

export default Index;
