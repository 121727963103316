import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { translate } from '@utils/i18n';
import { VisitPaneContext } from '../Contexts/VisitPaneContext';
import Error from '../../../components/Error';
import { VisitItemContextProvider } from './VisitItemContext';
import { Loader } from '../../../components/Loader/Loader';
import PaginationControls from './PaginationControls';
import { TenantContext } from '../../../contexts/tenant-context';
import VisitFullWidth from './VisitFullWidth';
const namespace = 'features.map_scheduler';
function VisitsPaneFullWidth() {
    const { tenant } = useContext(TenantContext);
    const { loading, visits, error } = useContext(VisitPaneContext);
    const paneRef = useRef(null);
    useEffect(() => {
        if (!loading && paneRef.current) {
            paneRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [loading]);
    const paneVisits = useMemo(() => {
        if (error)
            return React.createElement(Error, { error: error });
        return visits.map((visit) => {
            return (React.createElement(VisitItemContextProvider, { key: visit.key, visit: visit },
                React.createElement(VisitFullWidth, null)));
        });
    }, [visits, error]);
    return (React.createElement("div", { className: "map__pane--x-full" },
        React.createElement("div", { className: "pane__header" },
            React.createElement("h2", { className: "pane__title" }, translate('visits.title', { namespace })),
            React.createElement("span", { style: { display: 'flex', alignItems: 'center' } },
                loading && React.createElement(Loader, null),
                React.createElement(PaginationControls, null)),
            React.createElement("div", { style: {
                    width: 'calc(100% + 4.8rem)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '-1.5rem -2.4rem',
                    padding: '1.6rem 2.4rem'
                } },
                React.createElement("small", { style: { flex: '0 0 8%' } }, "Type"),
                React.createElement("small", { style: { flex: '0 0 10%' } }, "Building"),
                React.createElement("small", { style: { flex: '0 0 15%' } }, "Address"),
                React.createElement("small", { style: { flex: '0 0 10%' } }, "Scheduled Date"),
                React.createElement("small", { style: { flex: '0 0 8%' } }, "Status"),
                React.createElement("small", { style: { flex: '0 0 5%' } }, "Frequency"),
                React.createElement("small", { style: { flex: '0 0 10%' } }, "Description"),
                React.createElement("small", { style: { flex: '0 0 10%' } }, (tenant === null || tenant === void 0 ? void 0 : tenant.flags.subcontractorsEnabled) ? 'Assigned To' : 'Technician'),
                React.createElement("small", { style: { flex: '0 0 10%' } }, "Duration"))),
        React.createElement("div", { className: "pane__list", ref: paneRef, style: { height: '100%', overflow: 'scroll' } }, paneVisits)));
}
export default VisitsPaneFullWidth;
