import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { translate } from '@utils/i18n';
import { VisitPaneContext } from '../Contexts/VisitPaneContext';
import PaginationControls from './PaginationControls';
import { Loader } from '../../../components/Loader/Loader';
import { VisitItemContextProvider } from './VisitItemContext';
import Visit from './Visit';
import Error from '../../../components/Error';
const namespace = 'features.map_scheduler';
function VisitsPaneNew() {
    const { loading, visits, error } = useContext(VisitPaneContext);
    const paneRef = useRef(null);
    useEffect(() => {
        if (!loading && paneRef.current) {
            paneRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [loading]);
    const paneVisits = useMemo(() => {
        if (error)
            return React.createElement(Error, { error: error });
        return visits.map((visit) => {
            return (React.createElement(VisitItemContextProvider, { key: visit.key, visit: visit },
                React.createElement(Visit, null)));
        });
    }, [visits, error]);
    return (React.createElement("div", { className: "map__pane" },
        React.createElement("div", { className: "pane__header" },
            React.createElement("h2", { className: "pane__title" }, translate('visits.title', { namespace })),
            React.createElement("span", { style: { display: 'flex', alignItems: 'center' } },
                loading && React.createElement(Loader, null),
                React.createElement(PaginationControls, null))),
        React.createElement("div", { className: "pane__list", ref: paneRef, style: { height: '100%', overflow: 'scroll' } }, paneVisits)));
}
export default VisitsPaneNew;
