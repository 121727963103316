import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { translate } from '@utils/i18n';
import PropTypes from 'prop-types';
import Brushaway from '../../../../components/Brushaway';
import TaxItemForm from './TaxItemForm';
import { updateForm } from './Redux/taxItemFormSlice';
import { updateItems } from './Redux/taxItemSlice';
import abortableAxios from './hooks/abortableAxios';
import formatErrors from './helpers/formatErrors';

function CreateTaxItem({ activeItems, namespace }) {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.form.formData);
  const { request } = abortableAxios();

  const handleSubmitForm = async () => {
    try {
      const response = await request({
        method: 'POST',
        url: '/tax_items',
        data: { tax_item: formData }
      });
      dispatch(updateItems(response.data.tax_items));
      toast.success(translate('success_create_rate', { namespace }));
      return true;
    } catch (error) {
      toast.error(formatErrors(error?.response?.data?.errors) ?? translate('error_create_rate', { namespace }));
      return false;
    }
  };

  const setFormData = () => {
    dispatch(
      updateForm({
        active: true,
        is_tax_group: false,
        name: '',
        rate: '',
        tax_rate_ids: [],
        tax_rates_attributes: [],
        tax_agency: ''
      })
    );
  };

  const anchor = ({ onClick }) => (
    <div
      className="content-actions__group"
      style={{
        borderImage: 'none'
        // UNCOMMENT IF RE-ADDING ARCHIVE 'linear-gradient(to bottom, transparent 30%, #ccc 30%, #ccc 70%, transparent 70%) 0 1 0 0 / 1px'
      }}>
      <a
        className="qmb-control"
        href="#"
        onClick={() => {
          setFormData();
          onClick();
        }}>
        <i className="fa fa-light fa-circle-plus" />
        {translate('new', { namespace })}
      </a>
    </div>
  );

  return (
    <Brushaway
      anchor={anchor}
      title={translate('new', { namespace })}
      isForm
      onSubmit={handleSubmitForm}>
      <TaxItemForm taxRates={activeItems.filter((item) => item.type === 'TaxRate')} />
    </Brushaway>
  );
}

CreateTaxItem.propTypes = {
  activeItems: PropTypes.array.isRequired,
  namespace: PropTypes.string.isRequired
};

export default CreateTaxItem;
