import React, { useContext, useEffect, useMemo } from 'react';
import { ScheduleAndDispatchContext } from './ScheduleAndDispatchContext';
import { GlobalDragContext } from './GlobalDragContext';
import { TimeZoneContext } from '../../../contexts/timezone-context';
import { SchedulerContext } from './SchedulerContext';
export const ContextEventDispatchEvent = 'contextInformation';
function ContextEventDispatcher() {
    const scheduleAndDispatchState = useContext(ScheduleAndDispatchContext);
    const globalDragState = useContext(GlobalDragContext);
    const { timezone } = useContext(TimeZoneContext);
    const schedulerState = useContext(SchedulerContext);
    const contextInformation = useMemo(() => {
        return {
            openVisitModal: scheduleAndDispatchState === null || scheduleAndDispatchState === void 0 ? void 0 : scheduleAndDispatchState.openVisitModal,
            activeVisit: scheduleAndDispatchState === null || scheduleAndDispatchState === void 0 ? void 0 : scheduleAndDispatchState.activeVisit,
            startDragging: globalDragState === null || globalDragState === void 0 ? void 0 : globalDragState.startDragging,
            stopDragging: globalDragState === null || globalDragState === void 0 ? void 0 : globalDragState.stopDragging,
            timezone,
            assigneesOnMap: schedulerState === null || schedulerState === void 0 ? void 0 : schedulerState.assigneesOnMap,
            eventsGroupedByAssignee: schedulerState === null || schedulerState === void 0 ? void 0 : schedulerState.eventsGroupedByAssignee
        };
    }, [
        scheduleAndDispatchState === null || scheduleAndDispatchState === void 0 ? void 0 : scheduleAndDispatchState.openVisitModal,
        scheduleAndDispatchState === null || scheduleAndDispatchState === void 0 ? void 0 : scheduleAndDispatchState.activeVisit,
        globalDragState === null || globalDragState === void 0 ? void 0 : globalDragState.startDragging,
        globalDragState === null || globalDragState === void 0 ? void 0 : globalDragState.stopDragging,
        timezone,
        schedulerState === null || schedulerState === void 0 ? void 0 : schedulerState.assigneesOnMap,
        schedulerState === null || schedulerState === void 0 ? void 0 : schedulerState.eventsGroupedByAssignee
    ]);
    useEffect(() => {
        const contextEvent = new CustomEvent(ContextEventDispatchEvent, { detail: contextInformation });
        document.dispatchEvent(contextEvent);
    }, [contextInformation]);
    return null;
}
export default React.memo(ContextEventDispatcher);
