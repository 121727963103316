import React, { useContext } from 'react';
import { assigneeIcon, assigneeTitle } from '@utils/functions/assignee-functions';
import { isInspectionPrimaryVisit, isInspectionVisit, isServiceVisit } from '@utils/typeGuards/visit.typeguard';
import Chip from '../../../components/Chip/chip';
import { VisitItemContext } from './VisitItemContext';
const namespace = 'features.map_scheduler';
function Visit() {
    var _a, _b;
    const { visit, handleMouseDown, handleMouseUp } = useContext(VisitItemContext);
    return (React.createElement("li", { className: "list__option--visit", onMouseDown: (e) => handleMouseDown(e.nativeEvent), onMouseUp: (e) => handleMouseUp(e.nativeEvent) },
        React.createElement("button", { type: "button", style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement("span", { className: "option__type--fixed-width", title: "test" },
                React.createElement(TypeIcon, { visit: visit }),
                visit.type === "Visit" /* VisitType.SERVICE_VISIT */ ? 'Visit' : 'Inspection'),
            React.createElement("span", { className: "option__title--fixed-width" }, (_a = visit.building) === null || _a === void 0 ? void 0 : _a.name),
            React.createElement("span", { className: "option__address--fixed-width" }, (_b = visit.building) === null || _b === void 0 ? void 0 : _b.address),
            React.createElement("span", { className: "option__date--fixed-width" }, visit.start.format('M/D/YYYY @ hh:mm a')),
            React.createElement("span", { className: "option__status--fixed-width" },
                React.createElement("i", { className: visit.status.icon, style: { color: visit.status.color } }),
                "\u00A0",
                visit.status.label),
            React.createElement("span", { className: "option__freq--fixed-width" }, isInspectionPrimaryVisit(visit) || isInspectionVisit(visit) ? visit === null || visit === void 0 ? void 0 : visit.frequency : ''),
            React.createElement("span", { className: "option__desc--fixed-width" }, visit.description),
            React.createElement("span", { className: "option__tech--fixed-width" },
                React.createElement(Chip, { icon: assigneeIcon(visit.assignee, 16), label: assigneeTitle(visit.assignee) })),
            React.createElement("span", { className: "option__duration--fixed-width" }, visit.humanizedDuration))));
}
function TypeIcon({ visit }) {
    if (isServiceVisit(visit)) {
        return React.createElement("i", { className: "fa-light fa-screwdriver-wrench", title: "Work Order" });
    }
    if (isInspectionPrimaryVisit(visit) || isInspectionVisit(visit)) {
        return React.createElement("i", { className: "fa-light fa-clipboard-check", title: "Inspection" });
    }
    return React.createElement("i", { className: "fa-light fa-square-question" });
}
export default Visit;
