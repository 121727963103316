import { useState, useEffect } from 'react';
import { DefaultDebouceDelay } from '../constants/configs';
const useDebounce = (value, delay = DefaultDebouceDelay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [value, delay]);
    return debouncedValue;
};
export default useDebounce;
