var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useRef, useEffect, useContext } from 'react';
import I18n from '../../utils/i18n';
import { BrushawayContext } from './BrushawayContext';
import './styles.scss';
function Brushaway({ anchor, title, children, isForm = false, onSubmit, submitButtonText = I18n.t('components.brushaway.save'), minWidth = 400 }) {
    const { isOpen, buttonsDisabled, toggleBrushaway, closeBrushaway, setButtonsDisabled } = useContext(BrushawayContext);
    const brushawayRef = useRef(null);
    const isMounted = useRef(true);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);
    useEffect(() => {
        const handleKeyUp = (event) => {
            if (event.key === 'Escape' && isOpen) {
                closeBrushaway();
            }
        };
        const handleClickOutside = (event) => {
            const target = event.target;
            if (brushawayRef.current &&
                !brushawayRef.current.contains(target) &&
                target.closest('a, button') &&
                target.closest('[data-allow-click]') === null) {
                closeBrushaway();
            }
        };
        window.addEventListener('keyup', handleKeyUp);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('keyup', handleKeyUp);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, closeBrushaway]);
    const handleFormSubmit = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        setButtonsDisabled(true);
        if (onSubmit) {
            const result = yield onSubmit();
            if (isMounted.current) {
                setButtonsDisabled(false);
                if (result) {
                    closeBrushaway();
                }
            }
        }
        else {
            setButtonsDisabled(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        anchor && anchor({ onClick: toggleBrushaway }),
        React.createElement("div", { id: "brushaway", className: `qmb-dialog ${isOpen ? '--active' : ''} brushaway-dialog`, style: {
                display: isOpen ? 'flex' : 'none',
                position: 'fixed',
                top: 0,
                right: 0,
                height: '100%',
                minWidth: `${minWidth}px`
            }, role: "dialog", "aria-labelledby": "brushaway-title", ref: brushawayRef, "data-allow-click": true },
            React.createElement("div", { className: "qmb-modal brushaway-modal" },
                React.createElement("h4", { id: "brushaway-title" }, title),
                React.createElement("button", { type: "button", className: "dialog__close qmb-control--icon brushaway-close", onClick: closeBrushaway, title: I18n.t('components.brushaway.close'), tabIndex: -1 },
                    React.createElement("i", { className: "fa-light fa-xmark", "aria-hidden": "true" }),
                    React.createElement("span", { className: "sr-only" }, I18n.t('components.brushaway.close')))),
            React.createElement("div", { className: "dialog__body brushaway-body" },
                React.createElement("section", null, children)),
            React.createElement("div", { className: "brushaway-footer", style: {
                    minWidth: `${minWidth}px`
                } }, isForm ? (React.createElement(React.Fragment, null,
                React.createElement("button", { type: "button", className: "qmb-button mr-1 brushaway-button", onClick: closeBrushaway, disabled: buttonsDisabled }, I18n.t('components.brushaway.cancel')),
                React.createElement("button", { type: "button", className: "qmb-button--submit brushaway-button", onClick: (e) => {
                        handleFormSubmit(e).catch((err) => {
                            // shouldn't ever be triggered because handleFormSubmit
                            // should manage errors
                            console.error('Form submission failed', err);
                        });
                    }, disabled: buttonsDisabled }, submitButtonText))) : (React.createElement("button", { type: "button", className: "qmb-button--submit brushaway-button", onClick: closeBrushaway }, I18n.t('components.brushaway.close')))))));
}
Brushaway.defaultProps = {
    anchor: undefined,
    children: null,
    isForm: false,
    onSubmit: undefined,
    submitButtonText: I18n.t('components.brushaway.save'),
    minWidth: 400
};
export default Brushaway;
