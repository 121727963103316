import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { priceRulesPath, priceListPath, priceRulePath } from 'routes';
import api from '@utils/axios';
import I18n, { translate } from '@utils/i18n';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import SearchSelect from '@components/Kendo/SearchSelect';
import toast, { Toaster } from 'react-hot-toast';
import './index.scss';

const namespace = 'features.settings.price_rule_edit';

const categories = [
  { label: 'Service', value: 'service' },
  { label: 'Material', value: 'material' }
];

function PriceRuleEdit({ priceRule: initialPriceRule, isNewRecord, currency, types, notEditable }) {
  const [priceRule, setPriceRule] = useState(initialPriceRule);
  const [filteredTypes, setFilteredTypes] = useState(types);

  const onSave = (e) => {
    e.preventDefault();

    const formData = {
      price_rule: {
        price_list_id: priceRule.priceListId,
        rule_type: priceRule.ruleType,
        rule_application: priceRule.ruleApplication,
        proposed_correction_id: priceRule.proposedCorrectionId,
        proposed_correction_type_id: priceRule.proposedCorrectionTypeId,
        value: priceRule.value
      }
    };

    if (isNewRecord) {
      api
        .post(priceRulesPath(), formData)
        .then(() => {
          window.location.href = priceListPath(priceRule.priceListId);
        })
        .catch((error) => {
          toast.error(error.response.data.error, {
            position: 'bottom-right',
            autoClose: 3000,
            closeOnClick: true
          });
        });
    } else {
      api
        .put(priceRulePath(priceRule.id), formData)
        .then(() => {
          window.location.href = priceListPath(priceRule.priceListId);
        })
        .catch((error) => {
          toast.error(error.response.data.error, {
            position: 'bottom-right',
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  };

  const handleRuleTypeChange = (e) => {
    if (notEditable) return;

    setPriceRule((draft) => ({ ...draft, ruleType: e.target.value }));
  };

  const onFilterChange = (event) => {
    const filterQuery = event.filter.value;
    if (filterQuery === '') setFilteredTypes(types);

    const re = new RegExp(filterQuery, 'i');

    setFilteredTypes(types.filter((element) => re.test(element.label)));
  };

  const handleApplicationChange = (e) => {
    if (notEditable) return;

    setFilteredTypes(types);
    setPriceRule((draft) => ({
      ...draft,
      ruleApplication: e.target.value,
      proposedCorrectionTypeId: null,
      proposedCorrectionId: null
    }));
  };

  const handleValueChange = (e) => {
    if (notEditable) return;

    setPriceRule((draft) => ({ ...draft, value: e.target.value }));
  };

  const handleTypeChange = (e) => {
    setPriceRule((draft) => ({ ...draft, proposedCorrectionTypeId: e.target.value.value }));
  };

  const valueLabel = useMemo(() => {
    switch (priceRule.ruleType) {
      case 'fixed':
        return `Fixed Price (${currency})`;
      case 'discount':
        return 'Discount (%)';
      case 'markup':
        return 'Markup (%)';
    }
  }, [priceRule.ruleType]);

  const isInvalid = useMemo(() => {
    if (!priceRule.value > 0) return true;
    if (priceRule.ruleApplication === null) return true;
    if (priceRule.ruleApplication === 'type' && priceRule.proposedCorrectionTypeId === null) return true;
    if (priceRule.ruleApplication === 'product' && priceRule.proposedCorrectionId === null) return true;

    return false;
  }, [priceRule]);

  return (
    <div className="workspace__body">
      <Toaster position="bottom-right" />
      <div className="workspace__major--form">
        {notEditable && (
          <div className="qmb-blurb--callout--attention">
            <i className="fa-regular fa-file-circle-exclamation blurb__icon" />
            <div className="blurb__body">{translate('not_editable', { namespace })}</div>
          </div>
        )}
        <form className="qmb-form" onSubmit={onSave}>
          <div className="workspace__header--alt">
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} className="header__main">
              <h1 className="workspace__title">
                {isNewRecord && translate('add_price_rule', { namespace })}
                {!isNewRecord && translate('edit_price_rule', { namespace })}
              </h1>
              <button type="submit" disabled={notEditable || isInvalid} className="qmb-control--highlight">
                {I18n.t('generic.save')}
              </button>
            </div>
          </div>
          <div className="qmb-content-actions" />
          <section className="workspace__section" id="basic">
            <header className="section__title">
              <h2 className="section__label--section">{translate('setup', { namespace })}</h2>
              <hr className="section__split" />
            </header>

            <fieldset className="form__set">
              <div className="popup__section--group--y-fill">
                <h3 className="section__label--section">{translate('rule_type', { namespace })}</h3>
                <br />
                <label className="qmb-radio">
                  <input
                    name="ruleType"
                    type="radio"
                    value="fixed"
                    checked={priceRule.ruleType === 'fixed'}
                    onChange={handleRuleTypeChange}
                    disabled={notEditable}
                  />
                  {translate('fixed', { namespace })}
                </label>
                <label className="qmb-radio">
                  <input
                    name="ruleType"
                    type="radio"
                    value="discount"
                    checked={priceRule.ruleType === 'discount'}
                    onChange={handleRuleTypeChange}
                    disabled={notEditable}
                  />
                  {translate('discount', { namespace })}
                </label>
                <label className="qmb-radio">
                  <input
                    name="ruleType"
                    type="radio"
                    value="markup"
                    checked={priceRule.ruleType === 'markup'}
                    onChange={handleRuleTypeChange}
                    disabled={notEditable}
                  />
                  {translate('markup', { namespace })}
                </label>
              </div>
            </fieldset>

            {priceRule.ruleType && (
              <fieldset style={{ width: '100%' }}>
                <div className="qmb-input--number--x-quarter duration-field">
                  <input
                    disabled={notEditable}
                    className=""
                    type="number"
                    onChange={handleValueChange}
                    name="value"
                    value={priceRule.value}
                    min="0"
                    step="0.01"
                  />
                  <label htmlFor="value" className="qmb-label--required">
                    {valueLabel}
                  </label>
                </div>
              </fieldset>
            )}

            <div style={{ width: '100%' }} />

            <fieldset className="form__set">
              <div className="popup__section--group--y-fill">
                <h3 className="section__label--section">{translate('rule_application', { namespace })}</h3>
                <br />
                <label className="qmb-radio">
                  <input
                    name="ruleApplication"
                    type="radio"
                    value="all"
                    disabled={notEditable}
                    onChange={handleApplicationChange}
                    checked={priceRule.ruleApplication === 'all'}
                  />
                  {translate('all', { namespace })}
                </label>
                <label className="qmb-radio">
                  <input
                    name="ruleApplication"
                    type="radio"
                    value="material"
                    disabled={notEditable}
                    onChange={handleApplicationChange}
                    checked={priceRule.ruleApplication === 'service' || priceRule.ruleApplication === 'material'}
                  />
                  {translate('category', { namespace })}
                </label>
                <label className="qmb-radio">
                  <input
                    name="ruleApplication"
                    type="radio"
                    value="type"
                    disabled={notEditable}
                    checked={priceRule.ruleApplication === 'type'}
                    onChange={handleApplicationChange}
                  />
                  {translate('type', { namespace })}
                </label>
                <label className="qmb-radio">
                  <input
                    name="ruleApplication"
                    type="radio"
                    value="product"
                    disabled={notEditable}
                    checked={priceRule.ruleApplication === 'product'}
                    onChange={handleApplicationChange}
                  />
                  {translate('product', { namespace })}
                </label>
              </div>
            </fieldset>

            {(priceRule.ruleApplication === 'service' || priceRule.ruleApplication === 'material') && (
              <fieldset style={{ width: '100%' }}>
                <div className="qmb-select" style={{ width: '220px', height: '43px' }}>
                  <DropDownList
                    disabled={notEditable}
                    name="category"
                    id="category"
                    textField="label"
                    dataItemKey="value"
                    data={categories}
                    value={categories.find((c) => c.value === priceRule.ruleApplication)}
                    onChange={(e) => {
                      setPriceRule((draft) => ({ ...draft, ruleApplication: e.target.value.value }));
                    }}
                    size="large"
                  />
                  <label className="qmb-label">{translate('category', { namespace })}</label>
                </div>
              </fieldset>
            )}

            {priceRule.ruleApplication === 'type' && (
              <fieldset style={{ width: '100%' }}>
                <div className="qmb-select" style={{ width: '220px', height: '43px' }}>
                  <DropDownList
                    defaultItem={{ label: translate('select_type', { namespace }), value: null }}
                    name="category"
                    id="category"
                    filterable
                    onFilterChange={onFilterChange}
                    textField="label"
                    dataItemKey="value"
                    data={filteredTypes}
                    value={types.find((t) => t.value === priceRule.proposedCorrectionTypeId)}
                    disabled={notEditable}
                    onChange={handleTypeChange}
                    size="large"
                  />
                  <label className="qmb-label">{translate('type', { namespace })}</label>
                </div>
              </fieldset>
            )}

            {priceRule.ruleApplication === 'product' && (
              <fieldset style={{ width: '100%' }}>
                <div className="qmb-select" style={{ width: '220px', height: '43px' }}>
                  <SearchSelect
                    name="product"
                    value={priceRule.proposedCorrectionId}
                    dataUrl="/lookup?type=proposed_correction"
                    valueMapUrl="/lookup/map?type=proposed_correction"
                    label={translate('product', { namespace })}
                    placeholder={translate('select_product', { namespace })}
                    itemHeight={50}
                    disabled={notEditable}
                    selectOptions={{
                      id: 'product',
                      onChange: (e) => {
                        setPriceRule((draft) => ({ ...draft, proposedCorrectionId: e.sender.element[0].value }));
                      }
                    }}
                  />
                </div>
              </fieldset>
            )}
          </section>
        </form>
      </div>
    </div>
  );
}

PriceRuleEdit.propTypes = {
  isNewRecord: PropTypes.bool,
  currency: PropTypes.string.isRequired,
  types: PropTypes.array.isRequired,
  notEditable: PropTypes.bool.isRequired,
  priceRule: PropTypes.shape({
    id: PropTypes.number,
    ruleType: PropTypes.string.isRequired,
    ruleApplication: PropTypes.string.isRequired,
    priceListId: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    proposedCorrectionId: PropTypes.number,
    proposedCorrectionTypeId: PropTypes.number
  }).isRequired
};

PriceRuleEdit.defaultProps = {
  isNewRecord: false
};

export default PriceRuleEdit;
