import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useGetVisits from '@hooks/requests/visits.get.hook';
import useApiQuery from '@hooks/api-params.hook';
import useGreedyState from '@hooks/use-greedy-state.hook';
import { GlobalDragContext } from './GlobalDragContext';
import { ScheduleAndDispatchContext } from './ScheduleAndDispatchContext';
import { setVisits as setVisitsDispatch } from '../Redux/visitsSlice';
import { TenantContext } from '../../../contexts/tenant-context';
export const VisitPaneContext = createContext(undefined);
export function VisitPaneContextProvider(params) {
    const { tenant } = useContext(TenantContext);
    const visitParams = useMemo(() => {
        var _a, _b, _c, _d;
        return ({
            select: { page: 1, size: (_b = (_a = tenant === null || tenant === void 0 ? void 0 : tenant.configurations) === null || _a === void 0 ? void 0 : _a.schedulerVisitsPanePageSize) !== null && _b !== void 0 ? _b : 250 },
            filter: {
                type: params.filters.visitType === 'WorkOrder'
                    ? ["Visit" /* VisitType.SERVICE_VISIT */]
                    : params.filters.visitType === 'Inspection'
                        ? ["Inspection" /* VisitType.INSPECTION_PRIMARY_VISIT */, "InspectionVisit" /* VisitType.INSPECTION_VISIT */]
                        : [],
                range: {
                    start: params.filters.start,
                    end: params.filters.end
                },
                technicianIds: (_c = params.filters.assigneeTechIds) === null || _c === void 0 ? void 0 : _c.map((value) => value !== null && value !== void 0 ? value : 'unassigned'),
                subcontractorIds: (_d = params.filters.assigneeSubIds) === null || _d === void 0 ? void 0 : _d.map((value) => value !== null && value !== void 0 ? value : 'unassigned'),
                teamIds: params.filters.teamIds,
                assets: params.filters.assets,
                buildingIds: params.filters.buildingIds,
                territoryIds: params.filters.territoryIds,
                tagIds: params.filters.tagIds,
                zipCode: params.filters.zipCode,
                workOrderStatuses: params.filters.workOrderStatuses,
                inspectionStatuses: params.filters.inspectionStatuses,
                inspectionTypeIds: params.filters.inspectionTypeIds
            }
        });
    }, [params.filters]);
    const { apiParams: visitsQuery, nextPage, prevPage } = useApiQuery(visitParams);
    const { data: fetchedVisits, loading, error, totalPages } = useGetVisits(visitsQuery);
    const { data: visits, setData: setVisits } = useGreedyState(fetchedVisits);
    const activeVisit = useRef(undefined);
    const { modifiedEventWatch } = useContext(ScheduleAndDispatchContext);
    const { startDragging, stopDragging } = useContext(GlobalDragContext);
    const dispatch = useDispatch();
    const replaceVisit = useCallback((updatedVisit) => {
        const newState = visits === null || visits === void 0 ? void 0 : visits.map((v) => v.key === updatedVisit.key ? updatedVisit : v);
        setVisits(newState);
    }, [visits]);
    useEffect(() => {
        if (modifiedEventWatch) {
            replaceVisit(modifiedEventWatch);
        }
    }, [modifiedEventWatch]);
    const handleComponentExit = useCallback(() => {
        if (activeVisit.current) {
            startDragging(activeVisit.current, () => {
                stopDragging();
            });
        }
    }, []);
    const contextData = useMemo(() => {
        const value = {
            activeVisit,
            handleComponentExit,
            visits: visits !== null && visits !== void 0 ? visits : [],
            error,
            loading,
            currentPage: visitsQuery.select === 'all' ? 1 : visitsQuery.select.page,
            totalPages: totalPages !== null && totalPages !== void 0 ? totalPages : 1,
            nextPage,
            prevPage,
            mapActive: params.mapActive
        };
        return value;
    }, [loading, totalPages, visitsQuery, visits, error, params.mapActive]);
    // Temporary solution until refactor GoogleMap.jsx
    useEffect(() => {
        const transformedVisitsForLegacy = (visits === null || visits === void 0 ? void 0 : visits.map((visit) => {
            var _a, _b;
            return ({
                key: `${visit.type}-${visit.id}`,
                type: visit.type.toString(),
                id: visit.id,
                startTime: visit.start.toISOString(),
                endTime: visit.end.toISOString(),
                technicianId: ((_b = (_a = visit.assignee) === null || _a === void 0 ? void 0 : _a.technician) === null || _b === void 0 ? void 0 : _b.id) || null,
                building: visit.building.name,
                address: visit.building.address,
                title: visit.building.name,
                subtitle: visit.building.address,
                status: visit.status.key,
                statusObj: visit.status,
                statusColor: visit.status.color,
                statusIcon: visit.status.icon,
                humanizedStatus: visit.status.label,
                buildingId: visit.building.id,
                buildingObj: visit.building,
                lat: visit.building.latitude,
                lng: visit.building.longitude,
                description: null,
                assignee: visit.assignee
            });
        })) || [];
        dispatch(setVisitsDispatch(transformedVisitsForLegacy));
    }, [visits]);
    return React.createElement(VisitPaneContext.Provider, { value: contextData }, params.children);
}
