import React from 'react';
import { translate } from '@utils/i18n';
import PropTypes from 'prop-types';

function EditRateInformational({ namespace }) {
  return (
    <div
      style={{
        borderRadius: '8px',
        padding: '16px',
        backgroundColor: '#f9fafb',
        display: 'flex',
        alignItems: 'flex-start',
        maxWidth: '370px'
      }}>
      <div
        style={{
          marginRight: '1rem'
        }}>
        <i className="fa fa-light fa-circle-info" />
      </div>
      <div>{translate('edit_rate_informational', { namespace })}</div>
    </div>
  );
}

EditRateInformational.propTypes = {
  namespace: PropTypes.string.isRequired
};

export default EditRateInformational;
