import { loadCacheFromLocalStorage } from '@helpers/localStorage';
import { translate } from '@utils/i18n';
import LinkTableCell from '@components/Kendo/LinkTableCell';
import ContactCell from '../nested/ContactCell';
import ActionsCell from '../nested/ActionsCell';

const namespace = 'features.subcontractors.index';

export const INITIAL_FILTERS = {
  values: { name: '', status: '', active: '' },
  active: { name: true, status: true, active: true },
  operators: {}
};

export const INITIAL_PAGE_SKIP = 0;

export const INITIAL_PAGE = { skip: INITIAL_PAGE_SKIP, take: 25 };

export const INITIAL_SORT = [
  {
    field: 'name',
    dir: 'asc'
  }
];

export const ALL_COLUMNS = ['name', 'referenceNumber', 'status', 'address', 'contact', 'actions'];

export const ALL_COLUMNS_DATA = {
  name: {
    id: 'name',
    field: 'name',
    title: translate('headers.company_name', { namespace }),
    cell: LinkTableCell
    // width: 200,
  },
  referenceNumber: {
    id: 'referenceNumber',
    field: 'referenceNumber',
    title: translate('headers.reference_number', { namespace })
    // width: 150,
  },
  status: {
    id: 'status',
    field: 'status',
    // width: 110,
    title: translate('headers.status', { namespace })
  },
  address: {
    id: 'address',
    field: 'address',
    // width: 180,
    title: translate('headers.address', { namespace }),
    sortable: false
  },
  contact: {
    id: 'contact',
    field: 'contact',
    // width: 180,
    cell: ContactCell,
    title: translate('headers.contact', { namespace }),
    sortable: false
  },
  actions: {
    id: 'actions',
    field: 'actions',
    cell: ActionsCell,
    width: 130,
    title: translate('headers.actions', { namespace }),
    sortable: false
  }
};

export const getInitialData = ({ cacheKey, technicians }) => {
  const cache = loadCacheFromLocalStorage(cacheKey);
  if (cacheIsValid(cache)) {
    return { ...cache, date: new Date(cache.date), events: [], technicians, errorMessage: '', loading: true };
  }

  return {
    page: INITIAL_PAGE,
    sort: INITIAL_SORT,
    filters: INITIAL_FILTERS,
    columns: ALL_COLUMNS,
    loading: true
  };
};

const cacheIsValid = (cache) => {
  if (!cache) return false;

  return true;
};
