import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useVisitPatch from '@hooks/requests/visit-patch-hook';
import TimeOffModal from '../../Modals/TimeOffModal';
import VisitEditModal from '../../Modals/VisitModal';
import DevSettingsModal from '../DevSettingsModal';
const defaultConfig = {
    setVisitToScheduledOnAssignment: true
};
export const ScheduleAndDispatchContext = createContext(undefined);
export function ScheduleAndDispatchProvider(params) {
    const [activeModal, setActiveModal] = useState(null);
    const [modifiedEventWatch, setModifiedEventWatch] = useState(null);
    const [activeVisit, setActiveVisit] = useState(null);
    const configuration = useRef(defaultConfig);
    const { save: saveVisit, saving, result: savedVisit } = useVisitPatch();
    useEffect(() => {
        if (savedVisit)
            setModifiedEventWatch(savedVisit);
    }, [savedVisit]);
    const openTimeOffModal = useCallback((id) => {
        setActiveModal(React.createElement(TimeOffModal, { id: id, onClose: () => setActiveModal(null) }));
    }, []);
    const openVisitModal = useCallback((type, id) => {
        setActiveVisit({ type, id });
        setActiveModal(React.createElement(VisitEditModal, { id: id, type: type, onClose: () => {
                setActiveVisit(null);
                setActiveModal(null);
            }, onSave: (updatedVisit) => {
                setActiveVisit(null);
                setActiveModal(null);
                setModifiedEventWatch(updatedVisit);
            } }));
    }, []);
    const openExperimentalModal = useCallback(() => {
        setActiveModal(React.createElement(DevSettingsModal, { onClose: () => {
                setActiveModal(null);
            } }));
    }, []);
    const setVisitScheduledOnAssignment = useCallback((setScheduled) => {
        configuration.current = Object.assign(Object.assign({}, configuration.current), { setVisitToScheduledOnAssignment: setScheduled });
    }, []);
    const saveVisitOverride = useCallback((visit, originalState) => {
        if (configuration.current.setVisitToScheduledOnAssignment &&
            visit.assignee.key !== (originalState === null || originalState === void 0 ? void 0 : originalState.assignee.key) &&
            visit.status.key === 'pending') {
            saveVisit(Object.assign(Object.assign({}, visit), { status: Object.assign(Object.assign({}, visit.status), { key: 'scheduled' }) }));
        }
        else {
            saveVisit(visit);
        }
    }, []);
    // expose experimental modal to the window for console execution
    useEffect(() => {
        // @ts-ignore
        window.openExperimentalModal = openExperimentalModal;
        return () => {
            // @ts-ignore
            delete window.openExperimentalModal;
        };
    }, [openExperimentalModal]);
    const contextData = useMemo(() => {
        const value = {
            activeVisit,
            openVisitModal,
            openTimeOffModal,
            openExperimentalModal,
            activeModal,
            modifiedEventWatch,
            saveVisit: saveVisitOverride,
            saving,
            setVisitScheduledOnAssignment
        };
        return value;
    }, [activeModal, modifiedEventWatch, saving, activeVisit]);
    return (React.createElement(ScheduleAndDispatchContext.Provider, { value: contextData }, params.children));
}
