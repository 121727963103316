import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { store } from './Redux/store';
import TaxItems from './TaxItems';
import TaxItemForm from "./TaxItemForm";

function Index({ taxItems }) {
  return (
    <Provider store={store}>
      <TaxItems taxItems={taxItems} />
    </Provider>
  );
}

Index.propTypes = {
  taxItems: PropTypes.array.isRequired
};

export default Index;
