import humanizeField from './humanizeField';

const formatErrors = (errors) => {
  if (errors === null) {
    return null;
  }

  return Object.keys(errors)
    .map((field) => {
      const messages = errors[field].join(', ');
      return `${humanizeField(field)}: ${messages}`;
    })
    .join('\n');
};

export default formatErrors;
