import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { editTaxRatePath } from 'routes';
import { translate } from '@utils/i18n';
import ActionButtons from "./ActionButtons";
import EditTaxRate from "./EditTaxRate";

const namespace = 'features.settings.tax_items.index';

function ExpandableRow({ item, onSwitch, onDuplicate, qbEnabled = false }) {
  if (item.type !== 'TaxGroup' || !item.all_tax_rates) return null;

  return (
    <>
      {item.all_tax_rates
        .filter((rate) => rate.active)
        .map((rate) => (
          <tr key={`expanded-row-${rate.id}`} className="expanded-row hoverable">
            <td style={/* remove style if adding either button back */ { height: "30px", alignItems: "center" }}>
              {(rate.external_id === null && rate.reference_id === null) && (
                <div className="pl-4">
                  <EditTaxRate taxItem={rate} namespace={namespace} />
                </div>
              )}
              {(rate.external_id !== null || rate.reference_id !== null) && <span className="pl-4">{rate.name}</span>}
            </td>
            <td style={/* remove style if adding either button back */ { height: "30px", alignItems: "center" }}>{rate.tax_agency}</td>
            <td style={/* remove style if adding either button back */ { height: "30px", alignItems: "center" }}>{rate.rate}%</td>
            <td style={/* remove style if adding either button back */ { height: "30px", alignItems: "center" }}>
              <ActionButtons
                taxItem={rate}
                onSwitch={onSwitch(rate)}
                onDuplicate={onDuplicate(rate)}
                buttonDuplicated
                qbEnabled={qbEnabled}
              />
            </td>
          </tr>
        ))}
    </>
  );
}

ExpandableRow.propTypes = {
  item: PropTypes.object.isRequired,
  onSwitch: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  qbEnabled: PropTypes.bool.isRequired
};

export default ExpandableRow;
