import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Table, { TableSize } from '@components/Table/Table';
import { taxItemPath } from 'routes';
import api from '@utils/axios';
import { translate } from '@utils/i18n';
import { Provider, useSelector, useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import ActionButtons from './ActionButtons';
import ExpandableRow from './ExpandableRow';
import SearchPanel from '../../../../components/SearchPanel';
import Brushaway from '../../../../components/Brushaway';
import TaxItemForm from './TaxItemForm';
import CreateTaxItem from './CreateTaxItem';
import { store } from './Redux/store';
import EditTaxRate from './EditTaxRate';
import EditTaxGroup from './EditTaxGroup';
import { updateItems } from './Redux/taxItemSlice';

const namespace = 'features.settings.tax_items.index';

function TaxItems({ taxItems }) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items.items);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [search, setSearch] = useState(null);
  const [inactiveSearch, setInactiveSearch] = useState(null);
  const qbEnabled = taxItems.filter((item) => item.external_id !== null && item.active && !item.hidden).length > 0;

  useEffect(() => {
    setItems(taxItems);
  }, []);

  const setItems = (newItems) => {
    dispatch(updateItems(newItems));
  };

  const activeItems = useMemo(() => {
    return items.filter(
      (item) =>
        item.active === true && item.hidden === false && (!qbEnabled || (qbEnabled && item.external_id !== null))
    );
  }, [items]);

  const inactiveItems = useMemo(() => {
    return items.filter((item) => item.active === false && item.hidden === false);
  }, [items]);

  const filteredItems = useMemo(() => {
    if (search === null || search === undefined || search.trim() === '') {
      return activeItems;
    }
    return activeItems.filter((item) =>
      Object.values(item)
        .map((val) => (typeof val === 'string' ? val.toLowerCase() : val))
        .join(', ')
        .includes(search)
    );
  }, [activeItems, search]);

  const filteredInactiveItems = useMemo(() => {
    if (inactiveSearch === null || inactiveSearch === undefined || inactiveSearch.trim() === '') {
      return inactiveItems;
    }
    return inactiveItems.filter((item) =>
      Object.values(item)
        .map((val) => (typeof val === 'string' ? val.toLowerCase() : val))
        .join(', ')
        .includes(inactiveSearch)
    );
  }, [inactiveItems, inactiveSearch]);

  const onSwitch = (taxItem) => {
    return () => {
      api.put(taxItemPath(taxItem.id), { tax_item: { active: !taxItem.active } }).then((response) => {
        setItems(response.data.tax_items);
        toast.success(translate(taxItem.active === true ? 'success_deactivate' : 'success_activate', { namespace }));
      });
    };
  };

  const onDuplicate = (taxItem) => {
    return () => {
      api
        .post(`${taxItemPath(taxItem.id)}/duplicate`)
        .then((response) => {
          setItems(response.data.tax_items);
          toast.success(translate('success_duplicate', { namespace }));
        })
        .catch((error) => {
          toast.error(translate('failed_duplicate', { namespace }));
        });
    };
  };

  const onSearchChange = (value) => {
    setSearch(value.toLowerCase());
  };

  const onInactiveSearchChange = (value) => {
    setInactiveSearch(value.toLowerCase());
  };

  const toggleExpandGroup = (groupId, event) => {
    if (
      event.target.tagName === 'A' ||
      event.target.tagName === 'BUTTON' ||
      event.target.closest('[data-allow-click]') !== null
    ) {
      return;
    }

    setExpandedGroups((prev) => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const rowRenderer = ({ item }) =>
    expandedGroups[item.id] ? (
      <ExpandableRow item={item} onSwitch={onSwitch} onDuplicate={onDuplicate} qbEnabled={qbEnabled} />
    ) : null;

  const archivedAnchor = ({ onClick }) => (
    <div className="content-actions__group">
      <a className="qmb-control" href="#" onClick={onClick}>
        {translate('archived_rates', { namespace })}
      </a>
    </div>
  );

  const calculateGroupRate = (group) => {
    const num = group.all_tax_rates
      .filter((rate) => rate.active)
      .reduce((total, rate) => total + parseFloat(rate.rate), 0);
    return Number.isInteger(num) ? num.toFixed(1) : parseFloat(num.toFixed(5)).toString();
  };

  const columns = useMemo(
    () => [
      {
        label: translate('name', { namespace }),
        dataKey: 'name',
        renderer: ({ item }) => (
          <div style={/* remove style && change to <> if adding either button back */ { display: 'flex', height: '30px', alignItems: 'center' }}>
            {(item.external_id !== null || item.reference_id !== null || !item.active) && <span>{item.name}</span>}
            {item.external_id === null &&
              item.reference_id === null &&
              item.active === true &&
              item.type === 'TaxRate' && <EditTaxRate taxItem={item} namespace={namespace} />}
            {item.external_id === null &&
              item.reference_id === null &&
              item.active === true &&
              item.type === 'TaxGroup' && (
                <EditTaxGroup activeItems={activeItems} taxItem={item} namespace={namespace} />
              )}
            {item.type === 'TaxGroup' && (
              <i
                className={`fa fa-light pl-2 ${expandedGroups[item.id] ? 'fa-angle-up' : 'fa-angle-down'}`}
                style={{ cursor: 'pointer' }}
              />
            )}
            {item.external_id !== null && (
              <div className="inline">
                &nbsp;&nbsp;
                <img
                  height="16"
                  width="16"
                  src="/qbo.svg"
                  className="custom-icon--qbo"
                  title={translate('qb_icon', { namespace })}
                  alt={translate('qb_icon', { namespace })}
                />
              </div>
            )}
          </div>
        )
      },
      {
        label: translate('agency', { namespace }),
        dataKey: 'agency',
        renderer: ({ item }) => item.tax_agency
      },
      {
        label: translate('rate', { namespace }),
        dataKey: 'rate',
        renderer: ({ item }) => `${item.type === 'TaxGroup' ? calculateGroupRate(item) : item.rate}%`
      },
      {
        label: '',
        align: 'left',
        dataKey: 'id',
        width: 70,
        renderer: ({ item }) => (
          <ActionButtons
            taxItem={item}
            onSwitch={onSwitch(item)}
            onDuplicate={onDuplicate(item)}
            buttonDuplicated={!item.active}
            qbEnabled={qbEnabled}
          />
        )
      }
    ],
    [items, expandedGroups]
  );

  return (
    <div className="workspace__body">
      <Toaster position="bottom-right" />
      <div className="workspace__major--listing">
        <div className="workspace__header">
          <div className="header__main">
            <h1 className="workspace__title">{translate('title', { namespace })}</h1>
          </div>
        </div>

        <div className="qmb-content-actions">
          {!qbEnabled && <CreateTaxItem activeItems={activeItems} namespace={namespace} />}
          {/* ARCHIVED RATES BUTTON - UNCOMMENT TO RE-ADDING
          <Brushaway
            anchor={archivedAnchor}
            title="Archived Rates"
            minWidth={800}>
            {(filteredInactiveItems.length > 0 || inactiveSearch?.length > 0) && (
              <div className="width-50 pull-right py-2 pr-1">
                <SearchPanel
                  placeholder={translate('search', { namespace: 'filters' })}
                  onChange={onInactiveSearchChange}
                />
              </div>
            )}
            {filteredInactiveItems.length === 0 && (
              <div style={{ textAlign: 'center', marginTop: '25px', color: '#1967D2' }}>
                {translate('no_data', { namespace })}
              </div>
            )}
            {filteredInactiveItems.length > 0 && (
              <Table columns={columns} data={filteredInactiveItems} size={TableSize.Full} variant="listing" hover />
            )}
          </Brushaway>
          */}

          {(filteredItems.length > 0 || search?.length > 0) && (
            <div className="content-actions__group" style={{ marginLeft: 'auto' }}>
              <div>
                <SearchPanel placeholder={translate('search', { namespace: 'filters' })} onChange={onSearchChange} />
              </div>
            </div>
          )}
        </div>
        <div>
          {filteredItems.length === 0 && (
            <div style={{ textAlign: 'center', marginTop: '25px', color: '#1967D2' }}>
              {translate('no_data', { namespace })}
            </div>
          )}
          {filteredItems.length > 0 && (
            <Table
              columns={columns}
              data={filteredItems}
              size={TableSize.Full}
              variant="listing"
              expandableRowRenderer={rowRenderer}
              onRowClick={({ item }, event) => {
                if (item.type === 'TaxGroup') toggleExpandGroup(item.id, event);
              }}
              expandedRows={expandedGroups}
              hover
            />
          )}
        </div>
      </div>
    </div>
  );
}

TaxItems.propTypes = {
  taxItems: PropTypes.array.isRequired
};

export default TaxItems;
