import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { VisitPaneContext } from '../Contexts/VisitPaneContext';
import { ScheduleAndDispatchContext } from '../Contexts/ScheduleAndDispatchContext';
export const VisitItemContext = createContext(undefined);
export function VisitItemContextProvider(params) {
    const { activeVisit, handleComponentExit } = useContext(VisitPaneContext);
    const { openVisitModal } = useContext(ScheduleAndDispatchContext);
    const handleMouseDown = useCallback((e) => {
        e.stopPropagation();
        activeVisit.current = params.visit;
        document.addEventListener('mousemove', handleMouseMove);
    }, [params.visit]);
    const handleMouseMove = useCallback((e) => {
        handleComponentExit(e);
        document.removeEventListener('mousemove', handleMouseMove);
    }, [params.visit]);
    const handleMouseUp = useCallback((e) => {
        openVisitModal(params.visit.type, params.visit.id);
        document.removeEventListener('mousemove', handleMouseMove);
    }, [params.visit]);
    const contextData = useMemo(() => {
        const value = {
            visit: params.visit,
            handleMouseDown,
            handleMouseUp
        };
        return value;
    }, [params.visit]);
    return React.createElement(VisitItemContext.Provider, { value: contextData }, params.children);
}
