import React, { useState } from 'react';
import I18n, { translate } from '@utils/i18n';
import { Toaster } from 'react-hot-toast';
import useDebounce from '@hooks/useDebounce';
import { Loader, LoaderSizes } from '../../../../components/Loader/Loader';
import Table, { TableSize } from '../../../../components/Table/Table';
import SearchPanel from '../../../../components/SearchPanel';
import getColumnsDefinition from './columns';
import { namespace } from './constants';
import useExternalForms from './useExternalForms';
import './ExternalForms.scss';
import ExternalFormDetail from './ExternalFormDetail';
function ExternalFormsContainer({ frequenciesForSelect }) {
    const [searchText, setSearchText] = useState('');
    const searchTextDebounced = useDebounce(searchText);
    const { forms, loading, updateExternalForm } = useExternalForms({
        searchText: searchTextDebounced,
        frequencies: frequenciesForSelect
    });
    const [openDetailForm, setOpenDetailForm] = useState(null);
    const onClickItem = (form) => {
        setOpenDetailForm(form);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "workspace__body externalFormsContainer" },
            React.createElement(Toaster, { position: "bottom-right" }),
            React.createElement("div", { className: "workspace__major--listing" },
                React.createElement("div", { className: "workspace__header" },
                    React.createElement("div", { className: "header__main" },
                        React.createElement("h1", { className: "workspace__title" }, translate('title', { namespace })))),
                React.createElement("article", { id: "filters", className: "qmb-filters" },
                    React.createElement("div", { className: "filters__group--search" },
                        React.createElement(SearchPanel, { value: searchText, 
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                            placeholder: I18n.t('filters.search'), onChange: setSearchText }))),
                React.createElement("div", null,
                    loading && (React.createElement("div", { className: "loadingContainer" },
                        React.createElement(Loader, { size: LoaderSizes.xlarge }))),
                    !loading && (React.createElement(Table, { columns: getColumnsDefinition({ onClickItem }), data: forms, size: TableSize.Full, variant: "listing" })),
                    !loading && forms.length === 0 && (React.createElement("div", { className: "noDataContainer" }, translate('no_data', { namespace })))))),
        React.createElement(ExternalFormDetail, { form: openDetailForm, onClose: () => setOpenDetailForm(null), onSubmit: updateExternalForm, frequencies: frequenciesForSelect })));
}
export default ExternalFormsContainer;
