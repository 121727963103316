import React from 'react';
import UiSizes from '@enums/UiSizes';
import ImageAvatar from '../../Avatar/ImageAvatar';
import LetterAvatar from '../../Avatar/LetterAvatar';
export default function TextValueComp({ internalValue, options, allowLetterAvatar }) {
    if (options.some((opt) => Boolean(opt.icon) || allowLetterAvatar || opt.imgUrl)) {
        return options
            .filter((opt) => internalValue === null || internalValue === void 0 ? void 0 : internalValue.includes(opt.value))
            .map((opt) => {
            return (React.createElement("span", { key: opt.value, className: "valueSectionContainer" },
                opt.imgUrl && React.createElement(ImageAvatar, { src: opt.imgUrl, borderColor: opt.color || '#000', size: UiSizes.xSmall }),
                opt.icon && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "qmb-avatar--16--status-icon iconContainer" },
                        React.createElement("i", { className: opt.icon, style: { color: opt.color } })),
                    !allowLetterAvatar && (React.createElement("span", { style: { marginLeft: '0.4rem' }, key: opt.value }, opt.title)))),
                !opt.imgUrl && !opt.icon && allowLetterAvatar && (React.createElement(LetterAvatar, { letter: opt.title[0], backgroundColor: opt.color || '#000', size: UiSizes.xSmall }))));
        });
    }
    return (React.createElement(React.Fragment, null, options
        .filter((opt) => internalValue === null || internalValue === void 0 ? void 0 : internalValue.includes(opt.value))
        .map((opt) => opt.title)
        .join(', ')));
}
