import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import I18n, { translate } from '@utils/i18n';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const namespace = 'features.buildings.price_list_selector';

const accountInput = document.querySelector('[name*="building[account_id]"');

function PriceListSelector({ priceList, isNewRecord, accountsPriceLists, priceLists, associatedItems }) {
  const [priceListId, setPriceListId] = useState(priceList.id);
  const [openAsociated, setOpenAsociated] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [foundPriceListId, setFoundPriceListId] = useState('');
  const [filterQuery, setFilterQuery] = useState('');

  const ddListValue = () => {
    if (priceListId === undefined || priceListId === '') return null;

    return {
      value: priceListId,
      label: priceLists.find((elem) => elem.value === priceListId).label
    };
  };

  const onFilterChange = (event) => {
    setFilterQuery(event.filter.value);
  };

  const filteredPriceLists = useMemo(() => {
    if (filterQuery === '') return priceLists;

    const re = new RegExp(filterQuery, 'i');

    return priceLists.filter((list) => re.test(list.label));
  }, [filterQuery]);

  function handleAccountChange() {
    if (this.value === '') {
      setPriceListId(priceList.id);
      return;
    }

    const foundPriceId = accountsPriceLists.find((account) => account.id.toString() === this.value).price_list_id;

    setOpenAccount(true);
    setFoundPriceListId(foundPriceId);
  }

  useEffect(() => {
    if (!isNewRecord) return;

    accountInput.addEventListener('change', handleAccountChange);

    return () => {
      accountInput.removeEventListener('change', handleAccountChange);
    };
  }, [priceListId]);

  const onChange = (e) => {
    const newId = e.target.value.value;
    setPriceListId(newId);

    if (isNewRecord) return;

    if (priceList.id !== newId && Object.values(associatedItems).some((count) => count > 0)) setOpenAsociated(true);
  };

  const onCloseAssociatedModal = () => {
    setPriceListId(priceList.id);
    setOpenAsociated(false);
  };

  return (
    <>
      <div className="qmb-select">
        <label className="qmb-label">{I18n.t('features.buildings.price_list_selector.label')}</label>
        <DropDownList
          required
          filterable
          onFilterChange={onFilterChange}
          id="priceListSelector"
          className="qmb-popup"
          textField="label"
          dataItemKey="value"
          data={filteredPriceLists}
          value={ddListValue()}
          onChange={onChange}
        />
      </div>
      {openAsociated && (
        <Dialog
          title=<h4>{translate('dialog_title', { namespace })}</h4>
          className="qmb-dialog"
          width={600}
          onClose={onCloseAssociatedModal}>
          <div>
            {translate('note', { namespace, ...associatedItems })}
            <br />
            <br />
            {translate('subnote', { namespace })}
          </div>
          <br />
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button type="button" className="qmb-button" onClick={onCloseAssociatedModal}>
              {I18n.t('generic.cancel')}
            </button>
            <button
              onClick={() => {
                setOpenAsociated(false);
              }}
              type="submit"
              className="qmb-button--submit">
              {I18n.t('generic.confirm')}
            </button>
          </div>
        </Dialog>
      )}

      {openAccount && (
        <Dialog
          title={translate('account_title', { namespace })}
          className="qmb-dialog"
          onClose={() => {
            setOpenAccount(false);
          }}>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button
              type="button"
              className="qmb-button"
              onClick={() => {
                setOpenAccount(false);
              }}>
              {I18n.t('generic.cancel')}
            </button>
            <button
              onClick={() => {
                setPriceListId(foundPriceListId);
                setOpenAccount(false);
              }}
              type="submit"
              className="qmb-button--submit">
              {I18n.t('generic.confirm')}
            </button>
          </div>
        </Dialog>
      )}
      <input type="hidden" name="building[price_list_id]" value={priceListId} id="building_price_list_id" />
    </>
  );
}

PriceListSelector.propTypes = {
  priceList: PropTypes.object.isRequired,
  priceLists: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  isNewRecord: PropTypes.bool,
  accountsPriceLists: PropTypes.array.isRequired,
  associatedItems: PropTypes.shape({
    proposals: PropTypes.number,
    work_orders: PropTypes.number,
    inspections: PropTypes.number,
    invoices: PropTypes.number
  }).isRequired
};

PriceListSelector.defaultProps = {
  isNewRecord: false
};

export default PriceListSelector;
