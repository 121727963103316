import { useEffect, useState } from 'react';
import apiClient from '@utils/api-client';
import useVisitProcessor from '@hooks/processors/use-visit-processor';
import { apiInternalVisitsPath } from '../../routes';
const useGetVisits = (queryParams) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [totalCount, setTotalCount] = useState();
    const [totalPages, setTotalPages] = useState();
    const { processVisit } = useVisitProcessor();
    useEffect(() => {
        if (queryParams === null) {
            setData([]);
            return;
        }
        const controller = new AbortController();
        setLoading(true);
        setError(undefined);
        apiClient
            .get(apiInternalVisitsPath(), {
            params: queryParams,
            signal: controller.signal
        })
            .then((response) => {
            const result = response.data.data;
            const processedData = result.map((visit) => processVisit(visit));
            setData(processedData);
            setTotalCount(response.data.pagination.totalCount);
            setTotalPages(response.data.pagination.totalPages);
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setData([]);
                setError(err);
                setTotalPages(1);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        });
        return () => {
            controller.abort();
        };
    }, [queryParams]);
    return { data, loading, error, totalPages };
};
export default useGetVisits;
