import produce from 'immer';

export const actionTypes = {
  TABLE_RELOADED: 'TABLE_RELOADED',
  FILTER_CHANGED: 'FILTER_CHANGED',
  SEARCH_CHANGED: 'SEARCH_CHANGED',
  FILTERS_RESET: 'FILTERS_RESET',
  LOAD_FILTERS_FROM_CACHE: 'LOAD_FILTERS_FROM_CACHE'
};

export const buildInitialFiltersState = () => {
  return {
    status: '',
    category: '',
    serviceType: '',
    materialType: ''
  };
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.TABLE_RELOADED:
      draft.tableFingerprint = new Date().getTime();
      break;
    case actionTypes.FILTER_CHANGED:
      draft.filters[action.field] = action.value;
      break;
    case actionTypes.SEARCH_CHANGED:
      draft.search = action.search;
      break;
    case actionTypes.FILTERS_RESET:
      draft.filters = buildInitialFiltersState();
      break;
    case actionTypes.LOAD_FILTERS_FROM_CACHE:
      draft.filters = action.filters;
      break;
    default:
      throw new Error('Reducer - Wrong action type.');
  }
});
