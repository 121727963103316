import React, { useEffect, useCallback, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import FilterPanel from '@components/FilterPanel';
import SearchPanel from '@components/SearchPanel';
import I18n from '@utils/i18n';
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import api from '@utils/axios';
import { datatableBuildingsPath } from 'routes';
import { saveCacheToLocalStorage } from '../../../helpers/localStorage';
import { getInitialData } from './helpers/initialData';
import ColumnMenu from '@components/Kendo/ColumnMenu'
import ColumnMenuContext from '@components/Kendo/ColumnMenuContext'
import kendoDatatableParams from '../../../helpers/kendoDatatableParams';
import esMessages from '../../../locales/es.json';
import frMessages from '../../../locales/fr.json';
import './index.css';
import { actionTypes, reducer } from './reducer';
import { ALL_COLUMNS, ALL_COLUMNS_DATA, INITIAL_FILTERS, CACHE_KEY } from './constants';
import CustomScrollbar from '../../../components/CustomScrollbar/CustomScrollbar';
import FilterTypes from '../../../constants/FilterTypes.ts';

loadMessages(esMessages, 'es');
loadMessages(frMessages, 'fr');

function Index({ territoriesForSelect }) {
  const initialData = useMemo(() => getInitialData(CACHE_KEY), []);
  const [state, dispatch] = useReducer(reducer, {
    data: [],
    search: '',
    totalCount: 0,
    ...initialData
  });

  const { page, sort, filters, columns, data, totalCount, search, loading } = state;

  useEffect(() => {
    saveCacheToLocalStorage(CACHE_KEY, { page, sort, filters, columns });
  }, [page, sort, filters, columns]);

  useEffect(() => {
    dispatch({ type: actionTypes.LOADING_CHANGED, loading: true });
    api
      .get(datatableBuildingsPath(), { params: kendoDatatableParams({ page, sort, filters, search }) })
      .then(({ data: buildingsData }) => dispatch({ type: actionTypes.DATA_LOADED, data: buildingsData }))
      .finally(() => {
        dispatch({ type: actionTypes.LOADING_CHANGED, loading: false });
      });
  }, [page]);

  const onChangeFilter = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
    };
  };

  const handleMultiSelectFilterChange = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
    };
  };

  const onToggleFilter = (fieldName) => {
    return () => {
      dispatch({ type: actionTypes.FILTER_TOGGLED, field: fieldName });
    };
  };

  const onSearchChange = (value) => {
    dispatch({ type: actionTypes.SEARCH_CHANGED, search: value });
  };

  const filtersList = useMemo(
    () => [
      {
        field: 'account',
        locale: 'account',
        type: FilterTypes.GenericMultiSelect,
        active: filters.active.account,
        value: filters.values.account,
        onChange: handleMultiSelectFilterChange('account'),
        onToggle: onToggleFilter('account')
      },
      {
        field: 'status',
        locale: 'status',
        trueLabel: I18n.t('generic.active'),
        falseLabel: I18n.t('generic.inactive'),
        type: 'booleanSelect',
        active: filters.active.status,
        value: filters.values.status,
        onChange: onChangeFilter('status'),
        onToggle: onToggleFilter('status')
      },
      {
        field: 'tags',
        locale: 'tags',
        type: FilterTypes.GenericMultiSelect,
        active: filters.active.tags,
        value: filters.values.tags,
        onChange: handleMultiSelectFilterChange('tags'),
        onToggle: onToggleFilter('tags')
      },
      {
        field: 'territory',
        locale: 'territory',
        type: FilterTypes.GenericMultiSelect,
        active: filters.active.territory,
        value: filters.values.territory,
        onChange: handleMultiSelectFilterChange('territory'),
        onToggle: onToggleFilter('territory')
      },
      {
        field: 'name',
        locale: 'name',
        type: 'textBox',
        active: filters.active.name,
        value: filters.values.name,
        onChange: onChangeFilter('name'),
        onToggle: onToggleFilter('name')
      }
    ],
    [filters]
  );

  const onColumnsChange = useCallback(
    (cols) => dispatch({ type: actionTypes.COLUMNS_CHANGED, columns: cols.map((col) => col.id) }),
    []
  );

  const onResetFilters = () => {
    dispatch({ type: actionTypes.FILTER_CHANGED, filters: INITIAL_FILTERS });
  };

  const onResetCache = () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm(I18n.t('generic.are_you_sure'))) return;
    localStorage.removeItem(CACHE_KEY);
    window.location.reload();
  };

  const allColumnsWrapped = useMemo(() => ALL_COLUMNS.map((column) => ALL_COLUMNS_DATA[column]), []);
  const columnsWrapped = useMemo(() => columns.map((column) => ALL_COLUMNS_DATA[column]), [columns]);

  const columnContextValue = useMemo(
    () => ({
      onColumnsChange,
      columnsState: columnsWrapped,
      columns: allColumnsWrapped
    }),
    [columnsWrapped, allColumnsWrapped, onColumnsChange]
  );

  return (
    <LocalizationProvider language={I18n.locale}>
      <ColumnMenuContext.Provider value={columnContextValue}>
        <FilterPanel onResetFilters={onResetFilters} onResetCache={onResetCache} filters={filtersList}>
          <div className="filters__group--search">
            <SearchPanel placeholder={I18n.t('filters.search')} onChange={onSearchChange} />
          </div>
        </FilterPanel>
        {loading && (
          <div className="workspace__section">
            <div className="qmb-loading--96" data-title={I18n.t('generic.loading')}>
              <svg role="img">
                <use href="/map.svg#load-spinner" />
              </svg>
            </div>
          </div>
        )}
        {!loading && (
          <CustomScrollbar>
            <Grid
              className="qmb-grid--01"
              data={data}
              total={totalCount}
              skip={page.skip}
              take={page.take}
              onPageChange={(event) => {
                dispatch({ type: actionTypes.PAGE_CHANGED, page: event.page });
              }}
              pageable={{
                type: 'input',
                pageSizes: [25, 50, 100],
                pageSizeValue: page.take
              }}
              sortable
              sort={sort}
              onSortChange={(event) => {
                dispatch({ type: actionTypes.SORT_CHANGED, sort: event.sort });
              }}>
              {columnsWrapped.map((column) => (
                <Column
                  key={column.id}
                  field={column.field}
                  title={column.title}
                  columnMenu={ColumnMenu}
                  cell={column.cell}
                  sortable={column.sortable}
                  width={column.width}
                />
              ))}
            </Grid>
          </CustomScrollbar>
        )}
      </ColumnMenuContext.Provider>
    </LocalizationProvider>
  );
}

Index.propTypes = {
  territoriesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  )
};

Index.defaultProps = {
  territoriesForSelect: []
};

export default Index;
