import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { VariableSizeList as List } from 'react-window';
import moment from 'moment/moment';
import I18n, { translate } from '@utils/i18n';
import VisitItem from './VisitItem';
import { fetchVisits, saveVisit } from '../DAL/dataAccess';
import {
  setStatus,
  setVisitDateFilterMessage,
  setVisits,
  shiftSaveQueue,
  status as mapStatuses
} from '../Redux/visitsSlice';
import { setStatus as setSchedulerStatus, status as schedulerStatuses } from '../Redux/schedulerSlice';
import useVirtualizedList from '../Scheduler/useVirtualizedList';
import { TenantContext } from '../../../contexts/tenant-context';

const namespace = 'features.map_scheduler';

function VisitsPane() {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.map.filters);
  const mapActive = useSelector((state) => state.map.mapActive);

  const visitStatus = useSelector((state) => state.map.status);
  const saveQueue = useSelector((state) => state.map.saveQueue);
  const [listSize, setListSize] = useState({
    height: 265,
    width: 304
  });
  const containerRef = useRef(null);
  const { tenant } = useContext(TenantContext);

  useEffect(() => {
    if (saveQueue.length > 0) {
      dispatch(setSchedulerStatus(schedulerStatuses.SAVING));
      const event = saveQueue[0];

      saveVisit(event)
        .then(() => {
          dispatch(shiftSaveQueue());
          dispatch(setSchedulerStatus(schedulerStatuses.READY));
        })
        .catch((error) => {
          console.error(error);
          dispatch(setSchedulerStatus(schedulerStatuses.ERROR));
        });
    }
  }, [saveQueue]);

  useLayoutEffect(() => {
    const element = containerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      const newHeight = entries[0].contentRect.height;
      const newWidth = entries[0].contentRect.width;
      const widthOffset = 48; // 2.4rem padding
      if (newWidth !== listSize.width || newHeight !== listSize.height) {
        setListSize({ height: newHeight, width: newWidth + widthOffset });
      }
    });
    resizeObserver.observe(element);
    return () => resizeObserver.unobserve(element);
  }, []);

  const conditionalClass = () => {
    return !mapActive ? '--x-full' : '';
  };

  const renderTableHeaders = () => {
    if (visitStatus === mapStatuses.READY && !mapActive) {
      return (
        <div
          style={{
            width: 'calc(100% + 4.8rem)',
            display: 'flex',
            justifyContent: 'space-between',
            margin: '-1.5rem -2.4rem',
            padding: '1.6rem 2.4rem'
          }}>
          <small style={{ flex: '0 0 8%' }}>Type</small>
          <small style={{ flex: '0 0 10%' }}>Building</small>
          <small style={{ flex: '0 0 15%' }}>Address</small>
          <small style={{ flex: '0 0 10%' }}>Scheduled Date</small>
          <small style={{ flex: '0 0 8%' }}>Status</small>
          <small style={{ flex: '0 0 5%' }}>Frequency</small>
          <small style={{ flex: '0 0 10%' }}>Description</small>
          <small style={{ flex: '0 0 10%' }}>{tenant.flags.subcontractorsEnabled ? 'Assigned To' : 'Technician'}</small>
          <small style={{ flex: '0 0 10%' }}>Duration</small>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={`map__pane${conditionalClass()}`} ref={containerRef}>
      <div className="pane__header">
        <h2 className="pane__title">{translate('visits.title', { namespace })}</h2>
        {visitStatus === mapStatuses.LOADING && <Loading />}
        {visitStatus === mapStatuses.ERROR && <Error />}
        {renderTableHeaders()}
      </div>
      {visitStatus === mapStatuses.READY && <VisitsList listSize={listSize} />}
    </div>
  );
}

function Error() {
  return <span>An Error Occurred.</span>;
}

function Loading() {
  return (
    <div className="qmb-loading--32--alt" data-title={I18n.t('generic.loading')}>
      <svg role="img">
        <use href="/map.svg#load-spinner" />
      </svg>
    </div>
  );
}

function VisitsList({ listSize }) {
  const visits = useSelector((state) => state.map.visits);

  const { listRef, setSize, getSize } = useVirtualizedList();

  const listInfo = visits.slice().sort((a, b) => moment(a.startTime).diff(moment(b.startTime)));

  return (
    <List
      ref={listRef}
      height={listSize.height}
      itemCount={listInfo.length}
      itemSize={getSize}
      width={listSize.width}
      className="pane__list">
      {({ style, index }) => (
        <div style={style}>
          <VisitItem visit={listInfo[index]} onChangeSize={setSize} index={index} />
        </div>
      )}
    </List>
  );
}

export default React.memo(VisitsPane);

VisitsList.propTypes = {
  listSize: PropTypes.object
};

VisitsList.defaultProps = {
  listSize: null
};
