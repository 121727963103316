import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: []
};

const taxItemSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    updateItems(state, action) {
      state.items = action.payload;
    },
    resetItems(state) {
      state.items = initialState.items;
    }
  }
});

export const { updateItems, resetItems } = taxItemSlice.actions;

export default taxItemSlice.reducer;
