import React from 'react';
import { translate } from '@utils/i18n';
const namespace = 'features.settings.external_forms.index';
const getColumnsDefinition = ({ onClickItem }) => [
    {
        label: translate('form', { namespace }),
        dataKey: 'name'
    },
    {
        label: translate('frequency', { namespace }),
        dataKey: 'frequencyString'
    },
    {
        label: '',
        align: 'left',
        dataKey: 'id',
        width: 70,
        renderer: ({ item }) => (React.createElement("button", { type: "button", title: "Edit Details", className: "qmb-control--icon", "data-remote": "true", onClick: () => onClickItem(item) },
            React.createElement("i", { className: "fa-light fa-pencil", "aria-hidden": "true" })))
    }
];
export default getColumnsDefinition;
