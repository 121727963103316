import React, { useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes as dateRangePickerActionTypes } from '@components/Kendo/DateRangePickerReducer';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import I18n, { translate } from '@utils/i18n';
import dayjs from 'dayjs';
import VisitsPane from './VisitsPane';
import { setSettings } from './Redux/settingsSlice';
import Scheduler from './Scheduler';
import { resetFilters, setFilterValue, setVisitDateFilter, toggleFilter } from './Redux/visitsSlice';
import GoogleMap from './GoogleMap';
import FilterPanel from '../../components/FilterPanel';
import { saveCacheToLocalStorage } from '../Dashboards/InvoiceInsights/helpers/localStorage';
import {
  removeError,
  addError,
  setVisitStatusOnAssign,
  setStatus,
  setInitialActiveDay,
  status as schedulerStatuses
} from './Redux/schedulerSlice';
import SettingsPopover from './SettingsPopover';
import {
  CUSTOM_RANGE,
  LAST_MONTH,
  LAST_WEEK,
  NEXT_MONTH,
  NEXT_WEEK,
  THIS_MONTH,
  THIS_WEEK
} from '../../constants/Kendo/rangeOptions';
import { fetchVisitStatus } from './DAL/dataAccess';
import { setFleetInfo } from './Redux/fleetTrackingSlice';
import FleetTrackingModal from './FleetTrackingModal';
import FilterTypes from '../../constants/FilterTypes.ts';
import { SchedulerContextProvider } from './Contexts/SchedulerContext.tsx';
import { RearrangeContextProvider } from './Contexts/RearrangeContext.tsx';
import { GlobalDragContext } from './Contexts/GlobalDragContext.tsx';
import { VisitPaneContextProvider } from './Contexts/VisitPaneContext.tsx';
import { ScheduleAndDispatchContext } from './Contexts/ScheduleAndDispatchContext.tsx';
import { TenantContext } from '../../contexts/tenant-context.tsx';
import VisitsPaneNew from './VisitsPane/VisitsPane';
import VisitsPaneFullWidth from "./VisitsPane/VisitsPaneFullWidth";

const DEFAULT_CACHE_KEY = 'map_scheduler';
const VISIT_STATUS_SCHEDULED = 'scheduled';

const namespace = 'features.map_scheduler.index';

function MapScheduler({
  selectOptions,
  cacheKey,
  assetsForSelect,
  configuration,
  permissions,
  timeZone,
  fleetTracking
}) {
  useEffect(() => {
    dispatch(setSettings({ timeZone }));
  }, []);
  useEffect(() => {
    dispatch(setInitialActiveDay({ timeZone }));
  }, []);
  useEffect(() => {
    dispatch(setFleetInfo(fleetTracking));
  }, []);
  const kendoCacheKey = `kendo/${cacheKey}`;
  const { inspectionTypesForSelect, workOrderStatusesForSelect } = selectOptions;

  const mapActive = useSelector((state) => state.map.mapActive);
  const boardActive = useSelector((state) => state.scheduler.schedulerActive);
  const visitStatusScheduledActive = useSelector((state) => state.scheduler.visitStatusScheduledActive);
  const workspaceRef = useRef(null);
  const workspaceMapRef = useRef(null);
  const dispatch = useDispatch();
  const fleetTrackingModalTarget = useSelector((state) => state.fleet.selectedTech);
  const visitDateFilterMessage = useSelector((state) => state.map.visitDateFilterMessage);
  const filterValuesState = useSelector((state) => state.map.filters.values);
  const filterActiveState = useSelector((state) => state.map.filters.active);
  const errorList = useSelector((state) => state.scheduler.errorList);

  const schedulerFilters = useSelector((state) => state.scheduler.filters);
  const allFilters = useSelector((state) => state.map.filters);
  const filtersRef = useRef();
  filtersRef.current = allFilters;

  const { tenant } = useContext(TenantContext);
  const { dragItem } = useContext(GlobalDragContext);
  const { activeModal, setVisitScheduledOnAssignment } = useContext(ScheduleAndDispatchContext);

  const handleFilterChangeDate = (fieldName) => {
    return (dateFieldName, value) => {
      dispatch(
        setVisitDateFilter({
          type: dateRangePickerActionTypes.DATE_RANGE_FILTER_CHANGED,
          field: fieldName,
          dateField: dateFieldName,
          value
        })
      );
    };
  };

  const handleMultiSelectFilterChange = (fieldName) => {
    return (value) => {
      dispatch(setFilterValue({ field: fieldName, value }));
    };
  };

  const changeReactFilter = (fieldName) => {
    return (value) => {
      dispatch(setFilterValue({ field: fieldName, value }));
    };
  };

  const handleFilterToggle = (fieldName) => {
    return () => {
      dispatch(toggleFilter({ field: fieldName }));
    };
  };

  const handleTypeFilterChange = (e) => {
    dispatch(setFilterValue({ field: 'type', value: e.target.value }));
    dispatch(setFilterValue({ field: 'inspectionStatus', value: '' }));
    dispatch(setFilterValue({ field: 'inspectionType', value: '' }));
    dispatch(setFilterValue({ field: 'workOrderStatus', value: '' }));
  };

  const handleTypeFilterToggle = () => {
    dispatch(toggleFilter({ field: 'type' }));
    dispatch(setFilterValue({ field: 'inspectionStatus', value: '' }));
    dispatch(setFilterValue({ field: 'inspectionType', value: '' }));
    dispatch(setFilterValue({ field: 'workOrderStatus', value: '' }));
  };

  const handleResetCache = () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (!confirm(I18n.t('generic.are_you_sure'))) return;

    localStorage.removeItem(kendoCacheKey);
    window.location.reload();
  };

  const handleResizerMouseDown = () => {
    document.addEventListener('mousemove', handleResizerMouseMove);
    document.addEventListener('mouseup', handleResizerMouseUp, { once: true });
  };

  const handleResizerMouseMove = (e) => {
    if (!workspaceMapRef.current.style.flexBasis) {
      workspaceMapRef.current.style.flexBasis = `${workspaceMapRef.current.getBoundingClientRect().height}px`;
    } else {
      workspaceMapRef.current.style.flexBasis = `calc(${workspaceMapRef.current.style.flexBasis} + ${e.movementY}px)`;
    }
  };

  const handleResizerMouseUp = () => {
    document.removeEventListener('mousemove', handleResizerMouseMove);
    document.removeEventListener('mouseup', handleResizerMouseUp);
  };

  const inspectionStatusTemplate = ({ label, icon, color }) => {
    return `<div style="display: flex; align-items: center">
              <span class="qmb-avatar--24--status-icon" style="display: flex; border-radius: 50%; align-items: center; justify-content: center">
                <i class="fa-lg ${icon}" style="color: ${color}" />
              </span>
              <div style="margin-left: 0.8rem;">${label}</div>
            </div>`;
  };

  const inspectionStatusValueTemplate = ({ label, icon, color }) => {
    return `<span class="qmb-avatar--24--status-icon">
              <i class="fa-lg ${icon}" style="color: ${color}" />
            </span> ${label}`;
  };

  const mapPageFilters = [
    {
      field: 'visitDate',
      locale: 'visit_date',
      type: 'dateRangePicker',
      active: true,
      locked: true,
      label: 'Visit Date',
      values: filterValuesState.visitDate,
      onChange: handleFilterChangeDate('visitDate'),
      onToggle: handleFilterToggle('visitDate'),
      dateRanges: [LAST_MONTH, LAST_WEEK, THIS_WEEK, NEXT_WEEK, THIS_MONTH, NEXT_MONTH, CUSTOM_RANGE],
      message: visitDateFilterMessage
    },
    {
      field: 'technicianTeam',
      locale: 'team',
      type: FilterTypes.GenericMultiSelect,
      active: filterActiveState.technicianTeam,
      locked: false,
      value: filterValuesState.technicianTeam,
      onChange: handleMultiSelectFilterChange('technicianTeam'),
      onToggle: handleFilterToggle('technicianTeam')
    },
    {
      field: 'assignee',
      locale: tenant.flags.subcontractorsEnabled ? 'assigned_to' : 'technician',
      type: 'assignee',
      active: filterActiveState.assignee,
      locked: false,
      teamIds: filterValuesState.technicianTeam === '' ? null : filterValuesState.technicianTeam,
      technicianIds: filterValuesState.assigneeTechIds === 'all' ? [] : filterValuesState.assigneeTechIds,
      subcontractorIds: filterValuesState.assigneeSubIds === 'all' ? [] : filterValuesState.assigneeSubIds,
      onTechChange: changeReactFilter('assigneeTechIds'),
      onSubChange: changeReactFilter('assigneeSubIds'),
      onToggle: handleFilterToggle('assignee')
    },
    {
      field: 'asset',
      locale: 'asset',
      type: FilterTypes.TreeMultiSelect,
      active: filterActiveState.asset,
      value: filterValuesState.asset,
      optionsForSelect: assetsForSelect,
      onChange: handleMultiSelectFilterChange('asset'),
      onToggle: handleFilterToggle('asset')
    },
    {
      field: 'building',
      locale: 'building',
      type: FilterTypes.GenericMultiSelect,
      active: filterActiveState.building,
      locked: false,
      value: filterValuesState.building,
      onChange: handleMultiSelectFilterChange('building'),
      onToggle: handleFilterToggle('building')
    },
    {
      field: 'territory',
      locale: 'territory',
      type: FilterTypes.GenericMultiSelect,
      active: filterActiveState.territory,
      locked: false,
      value: filterValuesState.territory,
      onChange: handleMultiSelectFilterChange('territory'),
      onToggle: handleFilterToggle('territory')
    },
    {
      field: 'tags',
      locale: 'tags',
      type: FilterTypes.GenericMultiSelect,
      active: filterActiveState.tags,
      locked: false,
      value: filterValuesState.tags,
      onChange: handleMultiSelectFilterChange('tags'),
      onToggle: handleFilterToggle('tags')
    },
    {
      field: 'zipCode',
      backendField: 'zip_code',
      locale: 'postal_code',
      type: 'textBox',
      active: filterActiveState.zipCode,
      locked: false,
      value: filterValuesState.zipCode,
      onChange: changeReactFilter('zipCode'),
      onToggle: handleFilterToggle('zipCode')
    },
    {
      field: 'type',
      locale: 'visit_type',
      type: 'oldCommonSelect',
      active: filterActiveState.type,
      locked: false,
      optionsForSelect: [
        { value: 'WorkOrder', label: 'Work Order' },
        { value: 'Inspection', label: 'Inspection' }
      ],
      value: filterValuesState.type,
      onChange: handleTypeFilterChange,
      onToggle: handleTypeFilterToggle
    },
    {
      field: 'inspectionStatus',
      locale: 'inspection_status',
      backendField: 'status',
      disableSorting: true,
      type: FilterTypes.GenericMultiSelect,
      active: filterValuesState.type === 'Inspection',
      locked: true,
      value: filterValuesState.inspectionStatus,
      template: (dataItem) => inspectionStatusTemplate(dataItem),
      valueTemplate: (dataItem) => inspectionStatusValueTemplate(dataItem),
      onChange: handleMultiSelectFilterChange('inspectionStatus'),
      onToggle: handleFilterToggle('inspectionStatus'),
      itemHeight: 40,
      tooltip:
        filterValuesState.type === 'Inspection' ? undefined : translate('inspection_status_tooltip', { namespace })
    },
    {
      field: 'inspectionType',
      locale: 'inspection_type',
      type: FilterTypes.GenericMultiSelect,
      active: filterValuesState.type === 'Inspection',
      locked: true,
      optionsForSelect: inspectionTypesForSelect.map((opt) => ({ title: opt.label, value: opt.value })),
      value: filterValuesState.inspectionType,
      onChange: handleMultiSelectFilterChange('inspectionType'),
      onToggle: handleFilterToggle('inspectionType'),
      tooltip: filterValuesState.type === 'Inspection' ? undefined : translate('inspection_type_tooltip', { namespace })
    },
    {
      field: 'workOrderStatus',
      locale: 'work_order_status',
      type: FilterTypes.GenericMultiSelect,
      placeholder: 'filters.all_except_cancelled',
      active: filterValuesState.type === 'WorkOrder',
      locked: true,
      optionsForSelect: workOrderStatusesForSelect.map((opt) => ({ title: opt.label, value: opt.value })),
      value: filterValuesState.workOrderStatus,
      onChange: handleMultiSelectFilterChange('workOrderStatus'),
      onToggle: handleFilterToggle('workOrderStatus'),
      tooltip: filterValuesState.type === 'WorkOrder' ? undefined : translate('work_order_tooltip', { namespace })
    }
  ];

  useEffect(() => {
    fetchVisitStatus(VISIT_STATUS_SCHEDULED)
      .then((result) => dispatch(setVisitStatusOnAssign(result)))
      .catch((error) => {
        dispatch(setStatus(schedulerStatuses.ERROR));
        dispatch(addError(error.message));
      });
  }, []);

  useEffect(() => {
    setVisitScheduledOnAssignment(visitStatusScheduledActive);

    saveCacheToLocalStorage(kendoCacheKey, {
      filters: filtersRef.current,
      boardActive,
      mapActive,
      visitStatusScheduledActive
    });
  }, [allFilters, boardActive, mapActive, visitStatusScheduledActive]);

  useEffect(() => {
    if (boardActive) {
      if (!workspaceMapRef.current.style.flexBasis) {
        workspaceMapRef.current.style.flexBasis = `${workspaceMapRef.current.getBoundingClientRect().height}px`;
      } else {
        workspaceMapRef.current.style.flexBasis = '50%';
      }
    } else {
      workspaceMapRef.current.style.flexBasis = '100%';
    }
  }, [boardActive]);

  const onResetFilters = () => {
    dispatch(resetFilters());
  };

  const visitsPaneFilters = useMemo(
    () => ({
      start: dayjs(filterValuesState.visitDate.startDate).startOf('day').toDate(),
      end: dayjs(filterValuesState.visitDate.endDate).endOf('day').toDate(),
      assigneeTechIds: filterValuesState.assigneeTechIds,
      assigneeSubIds: filterValuesState.assigneeSubIds,
      teamIds: filterValuesState.technicianTeam,
      assets: filterValuesState.asset,
      buildingIds: filterValuesState.building,
      territoryIds: filterValuesState.territory,
      tagIds: filterValuesState.tags,
      zipCode: filterValuesState.zipCode,
      visitType: filterValuesState.type === '' ? undefined : filterValuesState.type,
      workOrderStatuses: filterValuesState.workOrderStatus,
      inspectionStatuses: filterValuesState.inspectionStatus,
      inspectionTypeIds: filterValuesState.inspectionType
    }),
    [filterValuesState]
  );

  return (
    <div ref={workspaceRef} className="workspace__major--desktop-full">
      {dragItem}
      {activeModal}
      {fleetTrackingModalTarget && <FleetTrackingModal />}

      <FilterPanel onResetFilters={onResetFilters} onResetCache={handleResetCache} filters={mapPageFilters}>
        <SettingsPopover permissions={permissions} />
      </FilterPanel>
      <hr className="workspace__split" />

      <SchedulerContextProvider teamId={schedulerFilters.values.technicianTeam}>
        <VisitPaneContextProvider filters={visitsPaneFilters}>
          <div ref={workspaceMapRef} className="workspace__map">
            {mapActive ? <VisitsPaneNew /> : <VisitsPaneFullWidth />}
            {mapActive && <GoogleMap centerLat={configuration.centerLat} centerLng={configuration.centerLng} />}
          </div>
        </VisitPaneContextProvider>

        {boardActive && (
          <>
            <div role="presentation" className="workspace__resizer--y-axis" onMouseDown={handleResizerMouseDown} />
            <hr className="workspace__split" />
            <RearrangeContextProvider>
              <Scheduler />
            </RearrangeContextProvider>
          </>
        )}
      </SchedulerContextProvider>

      <NotificationGroup
        style={{
          right: 0,
          bottom: 0,
          alignItems: 'flex-start',
          flexWrap: 'wrap-reverse'
        }}>
        <Fade>
          {errorList.map((e, idx) => (
            <Notification
              key={idx}
              type={{ style: 'error', icon: true }}
              closable
              onClose={() => {
                dispatch(removeError(e.key));
              }}>
              <span>{e.message}</span>
            </Notification>
          ))}
        </Fade>
      </NotificationGroup>
    </div>
  );
}

MapScheduler.propTypes = {
  cacheKey: PropTypes.string,
  selectOptions: PropTypes.object,
  assetsForSelect: PropTypes.array,
  configuration: PropTypes.object,
  permissions: PropTypes.object,
  timeZone: PropTypes.string,
  fleetTracking: PropTypes.object
};

MapScheduler.defaultProps = {
  cacheKey: DEFAULT_CACHE_KEY,
  selectOptions: {},
  assetsForSelect: [],
  configuration: {},
  permissions: {},
  timeZone: null,
  fleetTracking: null
};

export default MapScheduler;
