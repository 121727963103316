var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useState } from 'react';
import { translate } from '@utils/i18n';
import Brushaway from '../../../../components/Brushaway';
import { namespace } from './constants';
function ExternalFormDetail({ form, onClose, onSubmit, frequencies }) {
    const [editForm, setEditForm] = useState(form);
    useEffect(() => {
        if (form) {
            setEditForm(Object.assign(Object.assign({}, form), { frequencyIds: (form === null || form === void 0 ? void 0 : form.hasAllFrequencies) ? frequencies.map((f) => f.value) : (form === null || form === void 0 ? void 0 : form.frequencyIds) || [] }));
        }
    }, [form]);
    const handleSubmitForm = () => __awaiter(this, void 0, void 0, function* () {
        if (editForm) {
            return onSubmit(Object.assign(Object.assign({}, editForm), { hasAllFrequencies: allSelected, frequencyIds: allSelected ? [] : editForm.frequencyIds }));
        }
        return false;
    });
    const onChangeFrequency = (frequency) => {
        if (editForm) {
            if (editForm.frequencyIds.includes(frequency.value)) {
                setEditForm(Object.assign(Object.assign({}, editForm), { frequencyIds: editForm.frequencyIds.filter((id) => id !== frequency.value) }));
            }
            else {
                setEditForm(Object.assign(Object.assign({}, editForm), { frequencyIds: [...editForm.frequencyIds, frequency.value] }));
            }
        }
    };
    const allSelected = useMemo(() => {
        return (editForm === null || editForm === void 0 ? void 0 : editForm.frequencyIds.length) === frequencies.length;
    }, [frequencies, editForm === null || editForm === void 0 ? void 0 : editForm.frequencyIds]);
    const onSelectAllClicked = () => {
        if (editForm) {
            if (allSelected) {
                setEditForm(Object.assign(Object.assign({}, editForm), { frequencyIds: [] }));
            }
            else {
                setEditForm(Object.assign(Object.assign({}, editForm), { frequencyIds: frequencies.map((f) => f.value) }));
            }
        }
    };
    return (React.createElement(Brushaway, { open: form !== null, title: translate('configure_frequency', { namespace }), isForm: true, onClose: () => {
            onClose();
        }, onSubmit: handleSubmitForm },
        React.createElement("div", { className: "editExternalFormContainer" },
            React.createElement("div", null,
                translate('select_intervals', { namespace }),
                ":"),
            React.createElement("div", { className: "formSection" },
                React.createElement("div", { className: "qmb-checkbox" },
                    React.createElement("input", { className: "qmb-checkbox__input", type: "checkbox", id: "frequency-select-all", checked: allSelected, onChange: () => onSelectAllClicked() }),
                    React.createElement("label", { htmlFor: "frequency-select-all" }, translate('select_all', { namespace }))),
                frequencies.map((frequency) => (React.createElement("div", { key: frequency.value, className: "qmb-checkbox" },
                    React.createElement("input", { className: "qmb-checkbox__input", type: "checkbox", id: `frequency-${frequency.value}`, checked: (editForm === null || editForm === void 0 ? void 0 : editForm.frequencyIds.includes(frequency.value)) || false, onChange: () => onChangeFrequency(frequency) }),
                    React.createElement("label", { htmlFor: `frequency-${frequency.value}` }, frequency.label))))),
            React.createElement("div", { className: "noteSection" }, translate('edit_detail_note', { namespace, inspections: form === null || form === void 0 ? void 0 : form.inspectionsCount })))));
}
export default ExternalFormDetail;
