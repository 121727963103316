var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react';
import useDebounceFn from '@hooks/useDebounceFn';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader, LoaderSizes } from '../Loader/Loader';
import { DefaultDebouceDelay } from '../../constants/configs';
import './BasicSelectStyles.scss';
import LetterAvatar from '../Avatar/LetterAvatar';
import ImageAvatar from '../Avatar/ImageAvatar';
function BasicSelectComp(_a) {
    var _b, _c, _d;
    var { disableSorting = false, disableDebounce = false, fixedOptions = [], additionalOptions = [], allowLetterAvatar = false } = _a, params = __rest(_a, ["disableSorting", "disableDebounce", "fixedOptions", "additionalOptions", "allowLetterAvatar"]);
    const onChangeDebounced = useDebounceFn(params.onChange, DefaultDebouceDelay);
    const [internalValue, setInternalValue] = useState([]);
    useEffect(() => {
        if (Array.isArray(params.value)) {
            setInternalValue(params.value);
        }
        else if (params.value) {
            setInternalValue([params.value]);
        }
        else {
            setInternalValue([]);
        }
    }, [params.single ? params.value : (_c = (_b = params.value) === null || _b === void 0 ? void 0 : _b.join) === null || _c === void 0 ? void 0 : _c.call(_b, '')]);
    const onChangeOption = (option) => {
        let newValue;
        if (params.single) {
            newValue = internalValue.includes(option.value)
                ? internalValue.filter((val) => val !== option.value)
                : [option.value];
        }
        else {
            newValue = internalValue.includes(option.value)
                ? internalValue.filter((val) => val !== option.value)
                : [...internalValue, option.value];
        }
        setInternalValue(newValue);
        if (disableDebounce) {
            params.onChange(newValue);
        }
        else {
            onChangeDebounced(newValue);
        }
    };
    const handleClearFilter = () => {
        var _a;
        (_a = params.onChangeSearchText) === null || _a === void 0 ? void 0 : _a.call(params, '');
        setInternalValue([]);
        if (params.onReset) {
            params.onReset();
        }
    };
    const optionsFiltered = useMemo(() => fixedOptions
        .filter((opt) => {
        var _a;
        return opt.title.toLowerCase().includes(params.searchText.trim().toLowerCase()) ||
            ((_a = opt.subtitle) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(params.searchText.trim().toLowerCase()));
    })
        .sort((a, b) => {
        var _a, _b, _c, _d, _e, _f;
        // keep the same order if sorting is disabled
        if (disableSorting)
            return 0;
        // if both options are selected or both are not selected then sort by title
        if ((((_a = params.value) === null || _a === void 0 ? void 0 : _a.includes(a.value)) && ((_b = params.value) === null || _b === void 0 ? void 0 : _b.includes(b.value))) ||
            (!((_c = params.value) === null || _c === void 0 ? void 0 : _c.includes(a.value)) && !((_d = params.value) === null || _d === void 0 ? void 0 : _d.includes(b.value))))
            return a.title.localeCompare(b.title);
        // if only one option is selected then put it on top
        if ((_e = params.value) === null || _e === void 0 ? void 0 : _e.includes(a.value))
            return -1;
        if (!((_f = params.value) === null || _f === void 0 ? void 0 : _f.includes(a.value)))
            return 1;
        // any other case remain unchanged
        return 0;
    }), [fixedOptions, params.searchText, params.value, disableSorting]);
    return (React.createElement("div", { className: "qmb-popup--actions--y-max multiSelectPopupContainer", style: { zIndex: 1000 } },
        React.createElement("div", { className: "popup__section" },
            React.createElement("input", { className: "searchInput", onChange: (e) => { var _a; return (_a = params.onChangeSearchText) === null || _a === void 0 ? void 0 : _a.call(params, e.target.value); }, style: { border: 'none', padding: '1.2rem' }, placeholder: params.placeholder, value: params.searchText }),
            React.createElement("i", { className: "fa-light fa-magnifying-glass searchIcon" })),
        React.createElement("hr", { className: "popup__split" }),
        React.createElement("div", { className: "popup__section--y-fill" },
            React.createElement(InfiniteScroll, { loadMore: (_d = params.onLoadMore) !== null && _d !== void 0 ? _d : (() => undefined), hasMore: fixedOptions.length < Number(params.totalCount) && fixedOptions.length > 0 && !params.loadingOptions, useWindow: false },
                React.createElement("ul", { key: "container", role: "presentation" },
                    React.createElement("div", { className: "messageContainer" }, !params.loadingOptions && optionsFiltered.length === 0 && React.createElement("span", { className: "noResults" }, "No results")),
                    [...additionalOptions, ...optionsFiltered].map((option, idx) => (React.createElement("li", { key: `tech-option-${option.value}-idx-${idx}`, onClick: (e) => {
                            if (e.detail !== 1)
                                return;
                            onChangeOption(option);
                        } },
                        React.createElement("label", { className: "qmb-checkbox" },
                            React.createElement("input", { type: "checkbox", checked: internalValue.includes(option.value), readOnly: true }),
                            option.color && option.icon && (React.createElement("span", { className: "qmb-avatar--24--status-icon iconContainer" },
                                React.createElement("i", { className: option.icon, style: { color: option.color } }))),
                            option.color && option.imgUrl && (React.createElement(ImageAvatar, { src: option.imgUrl, borderColor: option.color || '#000' })),
                            !option.icon && !option.imgUrl && allowLetterAvatar && (React.createElement(LetterAvatar, { letter: option.title[0], backgroundColor: option.color || '#000' })),
                            React.createElement("p", { className: "qmb-checkbox-label", style: { padding: 0 }, onClick: (e) => {
                                    e.preventDefault();
                                } },
                                React.createElement("span", { className: "qmb-checkbox-title" }, option.title),
                                option.subtitle && React.createElement("span", { className: "qmb-checkbox-subtitle" }, option.subtitle)))))),
                    React.createElement("div", { className: "messageContainer" }, params.loadingOptions && React.createElement(Loader, { size: LoaderSizes.small }))))),
        React.createElement("hr", { className: "popup__split" }),
        React.createElement("div", { className: "popup__section" },
            React.createElement("ul", { role: "presentation" },
                React.createElement("li", null,
                    React.createElement("div", { className: "messageContainer" },
                        React.createElement("button", { type: "button", disabled: internalValue.length === 0, className: "qmb-control--sm--reset", onClick: handleClearFilter },
                            React.createElement("i", { className: "fa-light fa-arrow-rotate-left" }),
                            "Reset")))))));
}
export const BasicSelect = React.memo(BasicSelectComp);
