import React, { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const namespace = 'features.proposals.price_list_selector';

function PriceListSelector({ priceList, priceLists }) {
  const [priceListId, setPriceListId] = useState(priceList.id);
  const [filterQuery, setFilterQuery] = useState('');
  const input = useRef();

  const onChange = (e) => {
    const newId = e.target.value.value;
    setPriceListId(newId);
  };

  useEffect(() => {
    input.current.dispatchEvent(new Event('change'));
  }, [priceListId]);

  const ddListValue = () => {
    if (priceListId === undefined || priceListId === '') return null;

    return {
      value: priceListId,
      label: priceLists.find((elem) => elem.value === priceListId).label
    };
  };

  const onFilterChange = (event) => {
    setFilterQuery(event.filter.value);
  };

  const filteredPriceLists = useMemo(() => {
    if (filterQuery === '') return priceLists;

    const re = new RegExp(filterQuery, 'i');

    return priceLists.filter((list) => re.test(list.label));
  }, [filterQuery]);

  return (
    <div className="qmb-select">
      <label className="qmb-label">{translate('price_list', { namespace })}</label>
      <DropDownList
        required
        filterable
        onFilterChange={onFilterChange}
        id="priceListSelector"
        className="qmb-popup"
        textField="label"
        dataItemKey="value"
        data={filteredPriceLists}
        value={ddListValue()}
        onChange={onChange}
      />
      <input ref={input} type="hidden" name="proposal[price_list_id]" value={priceListId} />
    </div>
  );
}

PriceListSelector.propTypes = {
  priceList: PropTypes.object.isRequired,
  priceLists: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired
};

export default PriceListSelector;
