import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import useGetTenantDetails from '@hooks/requests/tenant-details.get.hook';
import useApiQuery from '@hooks/api-params.hook';
import useGetVisitStatuses from '@hooks/requests/visit-statuses.get.hook';
import TechnicianSortableAttributes from '@enums/sortable-attributes/technician-sortable-attributes.enum';
import useGetTechnicians from '@hooks/requests/technicians.get.hook';
import SubcontractorSortableAttributes from '@enums/sortable-attributes/subcontractor-sortable-attributes.enum';
import useGetSubcontractors from '@hooks/requests/subcontractors.get.hook';
import useGetVisit from '@hooks/requests/visit.get.hook';
import useVisitPatch from '@hooks/requests/visit-patch-hook';
import useGreedyState from '@hooks/use-greedy-state.hook';
const actionNotProvided = () => {
    console.warn('Action not provided to context');
};
const defaultContext = {
    modalReady: false,
    modalError: null,
    tenant: null,
    visit: null,
    saving: false,
    visitStatusList: [],
    subcontractorList: [],
    technicianList: [],
    closeModal: actionNotProvided,
    saveVisit: actionNotProvided
};
export const VisitModalContext = createContext(defaultContext);
export function VisitModalContextProvider(params) {
    // Load Tenant Details
    const { data: tenant, loading: tenantLoading, error: tenantError } = useGetTenantDetails();
    // Load Visit
    const { data: fetchedVisit, loading: visitLoading, error: visitError } = useGetVisit(params.type, params.id);
    const { data: visit, setData: setVisit } = useGreedyState(fetchedVisit);
    const { save: saveVisit, saving, result: savedVisit } = useVisitPatch();
    const { statusesParams, techniciansParams, subcontractorsParams } = useMemo(() => {
        const statusesFilters = { select: 'all' };
        const techniciansFilters = {
            select: 'all',
            sort: { attribute: TechnicianSortableAttributes.NAME, direction: 'asc' }
        };
        const subcontractorsFilters = {
            select: 'all',
            filter: {
                visitTypes: params.type === "Visit" /* VisitType.SERVICE_VISIT */ ? ["service" /* VisitType.SERVICE */] : ["inspection" /* VisitType.INSPECTION */]
            },
            sort: { attribute: SubcontractorSortableAttributes.NAME, direction: 'asc' }
        };
        return {
            statusesParams: statusesFilters,
            techniciansParams: techniciansFilters,
            subcontractorsParams: subcontractorsFilters
        };
    }, []);
    // Load Visit Statuses
    const { apiParams: statusQuery } = useApiQuery(statusesParams);
    const { data: visitStatusList, loading: visitStatusesLoading, error: visitStatusesError } = useGetVisitStatuses(params.type, statusQuery);
    // Load Technicians
    const { apiParams: techniciansQuery } = useApiQuery(techniciansParams);
    const { data: technicianList, loading: techniciansLoading, error: techniciansError } = useGetTechnicians(techniciansQuery);
    // Load Subcontractors conditionally on tenant flag
    const { apiParams: subcontractorsQuery } = useApiQuery(subcontractorsParams);
    const { data: subcontractorList, loading: subcontractorsLoading, error: subcontractorsError } = useGetSubcontractors(subcontractorsQuery, !!(tenant === null || tenant === void 0 ? void 0 : tenant.flags.subcontractorsEnabled));
    const modalDataInitializing = tenantLoading || visitLoading || visitStatusesLoading || techniciansLoading || subcontractorsLoading;
    const modalInitializationError = tenantError || visitError || visitStatusesError || techniciansError || subcontractorsError;
    const handleCloseModal = useCallback(() => {
        params.onClose();
    }, []);
    useEffect(() => {
        if (savedVisit) {
            setVisit(savedVisit);
            params.onSave(savedVisit);
        }
    }, [savedVisit]);
    const contextValue = useMemo(() => {
        const value = {
            modalReady: !modalDataInitializing,
            modalError: modalInitializationError !== null && modalInitializationError !== void 0 ? modalInitializationError : null,
            tenant: tenant !== null && tenant !== void 0 ? tenant : null,
            visit: visit !== null && visit !== void 0 ? visit : null,
            saving,
            visitStatusList,
            subcontractorList,
            technicianList,
            closeModal: handleCloseModal,
            saveVisit
        };
        return value;
    }, [
        tenant,
        visit,
        saveVisit,
        saving,
        visitStatusList,
        subcontractorList,
        technicianList,
        modalDataInitializing,
        modalInitializationError
    ]);
    return React.createElement(VisitModalContext.Provider, { value: contextValue }, params.children);
}
