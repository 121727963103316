import React, { useRef, useState, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import ClickAwayListener from 'react-click-away-listener';
import { translate } from '@utils/i18n';
import { CalendarContext } from '../../CalendarContext';
import { BasicSelect } from '../../../../components/BasicSelect/BasicSelect.tsx';
import { EntityTypePrefix } from '../../constants.ts';
import './Statuses.scss';

const namespace = 'features.calendar.header.statuses';

const MenuOptions = {
  Inspection: 'inspection',
  Service: 'service'
};

function Statuses() {
  const {
    state: { statuses },
    visitStatusesForHighlight,
    inspectionStatusesForSelect,
    permissions,
    actions: { onToggleStatus, toggleUnselectAllInspectionStatuses, toggleUnselectAllVisitStatuses }
  } = useContext(CalendarContext);

  const [inspSearchText, setInspSearchText] = useState('');
  const [visitSearchText, setVisitSearchText] = useState('');

  const anchor = useRef(null);
  const popup = useRef(null);
  const [open, setOpenMainMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);

  const menuAnchor = useRef(null);

  const selectedStatusIds = useMemo(
    () =>
      inspectionStatusesForSelect
        .filter((status) => statuses[`Inspection${status.value}`])
        .concat(visitStatusesForHighlight.filter((status) => statuses[`Visit${status.value}`])),
    [statuses]
  );

  const onAddEventPopup = useCallback(() => {
    setOpenMainMenu(!open);
  }, [open]);

  const inspectionOptions = useMemo(
    () => inspectionStatusesForSelect.map(({ label, ...statusInfo }) => ({ title: label, ...statusInfo })),
    [inspectionStatusesForSelect]
  );

  const visitOptions = useMemo(
    () => visitStatusesForHighlight.map(({ label, ...statusInfo }) => ({ title: label, ...statusInfo })),
    [visitStatusesForHighlight]
  );

  const inspectionStatusesValue = useMemo(
    () =>
      (Object.keys(statuses) || [])
        .filter((key) => key.startsWith(EntityTypePrefix.Inspection) && statuses[key])
        .map((key) => key.split(EntityTypePrefix.Inspection)[1]),
    [statuses]
  );

  const visitStatusesValue = useMemo(
    () =>
      (Object.keys(statuses) || [])
        .filter((key) => key.startsWith(EntityTypePrefix.Visit) && statuses[key])
        .map((key) => key.split(EntityTypePrefix.Visit)[1]),
    [statuses]
  );

  return (
    <>
      <button type="button" className="qmb-control--menu" ref={anchor} onClick={onAddEventPopup}>
        {translate('title', { namespace })}
        {selectedStatusIds.length > 0 && (
          <span className="control__collection">
            {selectedStatusIds.slice(0, 5).map((status, index) => (
              <StatusIcon key={index} status={status} />
            ))}

            {selectedStatusIds.length > 5 && (
              <span className="control__count">&#43;{selectedStatusIds.length - 5}</span>
            )}
          </span>
        )}
      </button>
      <Popup anchor={anchor.current} show={open} ref={popup} id="statushighlight-parent-popup">
        <ClickAwayListener
          onClickAway={() => {
            setInspSearchText('');
            setVisitSearchText('');
            setActiveMenu(null);
            setOpenMainMenu(null);
          }}>
          <div className="qmb-popup--actions--y-max highlightStatusContainer">
            <div className="qmb-popup--actions--y-max" ref={menuAnchor}>
              <button
                className="qmb-control--menu buttonContainer"
                type="button"
                onClick={() => setActiveMenu(MenuOptions.Inspection)}>
                <span>{translate('inspection_statuses', { namespace })}</span>
                <span className="counter">
                  {inspectionStatusesValue.length > 0 ? inspectionStatusesValue.length : null}
                </span>
              </button>
              {permissions.hasService && (
                <button
                  className="qmb-control--menu buttonContainer"
                  type="button"
                  onClick={() => setActiveMenu(MenuOptions.Service)}>
                  <span>{translate('wo_statuses', { namespace })}</span>
                  <span className="counter">{visitStatusesValue.length > 0 ? visitStatusesValue.length : null}</span>
                </button>
              )}
              {inspectionStatusesValue.length + visitStatusesValue.length > 0 && (
                <>
                  <hr className="popup__split" />
                  <div className="popup__section">
                    <ul role="presentation">
                      <li>
                        <div className="messageContainer">
                          <button
                            type="button"
                            className="qmb-control--sm--reset"
                            onClick={() => {
                              toggleUnselectAllInspectionStatuses();
                              toggleUnselectAllVisitStatuses();
                              setActiveMenu(null);
                              setOpenMainMenu(null);
                            }}>
                            <i className="fa-light fa-arrow-rotate-left" />
                            {translate('reset', { namespace: 'filters' })}
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
            <Popup
              anchor={document.getElementById('statushighlight-parent-popup')}
              show={activeMenu === MenuOptions.Inspection}
              anchorAlign={{ horizontal: 'right', vertical: 'top' }}
              popupAlign={{ horizontal: 'left', vertical: 'top' }}>
              <BasicSelect
                disableDebounce
                searchText={inspSearchText}
                onChangeSearchText={setInspSearchText}
                name="inspection"
                disableDebounce
                disableSorting
                value={inspectionStatusesValue}
                fixedOptions={inspectionOptions}
                onReset={() => {
                  setInspSearchText('');
                  toggleUnselectAllInspectionStatuses();
                }}
                placeholder={`Search ${translate(`plural_inspection_status`, { namespace: 'filters' })}`}
                onChange={(value) => {
                  const statusChanged = [...value, ...inspectionStatusesValue].find(
                    (val) =>
                      (!inspectionStatusesValue.includes(val) && value.includes(val)) ||
                      (inspectionStatusesValue.includes(val) && !value.includes(val))
                  );
                  onToggleStatus(`${EntityTypePrefix.Inspection}${statusChanged}`);
                }}
              />
            </Popup>
            <Popup
              anchor={document.getElementById('statushighlight-parent-popup')}
              show={activeMenu === MenuOptions.Service}
              anchorAlign={{ horizontal: 'right', vertical: 'top' }}
              popupAlign={{ horizontal: 'left', vertical: 'top' }}>
              <BasicSelect
                disableDebounce
                searchText={visitSearchText}
                onChangeSearchText={setVisitSearchText}
                name="service"
                disableDebounce
                value={visitStatusesValue}
                fixedOptions={visitOptions}
                placeholder={`Search ${translate(`plural_visit_status`, { namespace: 'filters' })}`}
                onReset={() => {
                  setVisitSearchText('');
                  toggleUnselectAllVisitStatuses();
                }}
                onChange={(value) => {
                  const statusChanged = [...value, ...visitStatusesValue].find(
                    (val) =>
                      (!visitStatusesValue.includes(val) && value.includes(val)) ||
                      (visitStatusesValue.includes(val) && !value.includes(val))
                  );
                  onToggleStatus(`${EntityTypePrefix.Visit}${statusChanged}`);
                }}
              />
            </Popup>
          </div>
        </ClickAwayListener>
      </Popup>
    </>
  );
}

function StatusIcon({ status }) {
  return (
    <span className="qmb-status">
      <i style={{ color: status.color }} className={status.icon} />
    </span>
  );
}

StatusIcon.propTypes = {
  status: PropTypes.shape({
    color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  }).isRequired
};

export default Statuses;
