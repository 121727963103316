var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// I18n comes from Sprockets now
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { I18n } = window;
export const translate = (key, params) => {
    const { namespace } = params, paramsRest = __rest(params, ["namespace"]);
    const prefix = namespace ? `${namespace}.` : undefined;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return I18n.t(`${prefix || ''}${key}`, paramsRest);
};
export default I18n;
