import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formData: {
    active: true,
    is_tax_group: false,
    name: '',
    rate: '',
    tax_rate_ids: [],
    tax_rates_attributes: [],
    tax_agency: ''
  }
};

const taxItemFormSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateForm(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetForm(state) {
      state.formData = initialState.formData;
    }
  }
});

export const { updateForm, resetForm } = taxItemFormSlice.actions;

export default taxItemFormSlice.reducer;
