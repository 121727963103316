import React, { useContext } from 'react';
import { VisitPaneContext } from '../Contexts/VisitPaneContext';
function PaginationControls() {
    const { loading, currentPage, totalPages, prevPage, nextPage } = useContext(VisitPaneContext);
    return (React.createElement("div", { className: "schedule__controls" },
        React.createElement("button", { className: "qmb-control--icon", onClick: prevPage, disabled: currentPage === 1 || loading },
            React.createElement("i", { className: "fa-light fa-angle-left" })),
        React.createElement("span", { style: { userSelect: 'none' } },
            currentPage,
            " / ",
            totalPages),
        React.createElement("button", { className: "qmb-control--icon", onClick: nextPage, disabled: currentPage === totalPages || loading },
            React.createElement("i", { className: "fa-light fa-angle-right" }))));
}
export default PaginationControls;
