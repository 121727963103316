import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import I18n, { translate } from '@utils/i18n';
import { Dialog } from '@progress/kendo-react-dialogs';

const namespace = 'features.accounts.price_list_selector';

function PriceListSelector({ priceList, associatedItems, priceLists, isNewRecord }) {
  const [priceListId, setPriceListId] = useState(priceList.id);
  const [open, setOpen] = useState(false);
  const [changeAssociatedBuildings, setChangeAssociatedBuildings] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');

  const ddListValue = () => {
    if (priceListId === undefined || priceListId === '') return null;

    return {
      value: priceListId,
      label: priceLists.find((elem) => elem.value === priceListId).label
    };
  };

  const onChange = (e) => {
    const newId = e.target.value.value;
    setPriceListId(newId);

    if (isNewRecord) return;

    if (priceList.id !== newId) setOpen(true);
  };

  const onCloseModal = () => {
    setPriceListId(priceList.id);
    setChangeAssociatedBuildings(false);
    setOpen(false);
  };

  const onFilterChange = (event) => {
    setFilterQuery(event.filter.value);
  };

  const filteredPriceLists = useMemo(() => {
    if (filterQuery === '') return priceLists;

    const re = new RegExp(filterQuery, 'i');

    return priceLists.filter((list) => re.test(list.label));
  }, [filterQuery]);

  return (
    <>
      <div className="qmb-select--blank" style={{ width: '220px' }}>
        <DropDownList
          required
          filterable
          onFilterChange={onFilterChange}
          id="priceListSelector"
          className="qmb-popup"
          textField="label"
          dataItemKey="value"
          data={filteredPriceLists}
          value={ddListValue()}
          onChange={onChange}
        />
      </div>
      {open && (
        <Dialog
          title=<h4>{translate('dialog_title', { namespace })}</h4>
          className="qmb-dialog"
          width={600}
          onClose={onCloseModal}>
          <label className="qmb-checkbox" style={{ color: '#424242', fontSize: '16px' }}>
            <input
              type="checkbox"
              onChange={() => {
                setChangeAssociatedBuildings((old) => !old);
              }}
              checked={changeAssociatedBuildings}
            />
            {translate('buildings_associated', { namespace })}
          </label>
          {Object.values(associatedItems).some((count) => count > 0) && (
            <div className="qmb-textarea">
              <br />
              {translate('note', { namespace, ...associatedItems })}
              <br />
              <br />
              {translate('subnote', { namespace })}
            </div>
          )}
          <div className="modal__footer pull-right mt-2" style={{ justifyContent: 'space-between' }}>
            <button type="button" className="qmb-button" onClick={onCloseModal}>
              {I18n.t('generic.cancel')}
            </button>
            <button
              onClick={() => {
                setOpen(false);
              }}
              type="submit"
              className="qmb-button--submit">
              {I18n.t('generic.confirm')}
            </button>
          </div>
        </Dialog>
      )}
      {changeAssociatedBuildings && (
        <input type="hidden" name="account[change_associated_buildings_price_lists]" value />
      )}
      <input type="hidden" name="account[price_list_id]" value={priceListId} />
    </>
  );
}

PriceListSelector.propTypes = {
  priceList: PropTypes.object.isRequired,
  priceLists: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  isNewRecord: PropTypes.bool.isRequired,
  associatedItems: PropTypes.shape({
    proposals: PropTypes.number,
    work_orders: PropTypes.number,
    inspections: PropTypes.number,
    invoices: PropTypes.number
  }).isRequired
};

export default PriceListSelector;
