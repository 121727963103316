import React, { useReducer, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { newSubcontractorPath, datatableSubcontractorsPath } from 'routes';
import api from '@utils/axios';
import I18n, { translate } from '@utils/i18n';
// import toast, { Toaster } from 'react-hot-toast';
import FilterPanel from '@components/FilterPanel';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import SearchPanel from '@components/SearchPanel';
import kendoDatatableParams from '@helpers/kendoDatatableParams';
import { saveCacheToLocalStorage } from '@helpers/localStorage';
import ColumnMenu from '@components/Kendo/ColumnMenu';
import ColumnMenuContext from '@components/Kendo/ColumnMenuContext';
import FilterTypes from '../../../constants/FilterTypes.ts';
import { actionTypes, reducer } from './reducer';
import { getInitialData, ALL_COLUMNS_DATA, ALL_COLUMNS, INITIAL_FILTERS } from './helpers/initialData';
import './index.css';

const namespace = 'features.subcontractors.index';

const CACHE_KEY = 'kendo/subcontractors/index';

function Index({ cacheKey, statusesForSelect }) {
  const [state, dispatch] = useReducer(reducer, { cacheKey }, getInitialData);

  const { page, sort, filters, columns, data, totalCount, search, loading } = state;

  const onChangeFilter = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
    };
  };

  const onToggleFilter = (fieldName) => {
    return () => {
      dispatch({ type: actionTypes.FILTER_TOGGLED, field: fieldName });
    };
  };

  const onSearchChange = (value) => {
    dispatch({ type: actionTypes.SEARCH_CHANGED, search: value });
  };

  useEffect(() => {
    dispatch({ type: actionTypes.LOADING_CHANGED, loading: true });
    api
      .get(datatableSubcontractorsPath(), { params: kendoDatatableParams({ page, sort, filters, search }) })
      .then(({ data: subcontractorsData }) => dispatch({ type: actionTypes.DATA_LOADED, data: subcontractorsData }))
      .finally(() => {
        dispatch({ type: actionTypes.LOADING_CHANGED, loading: false });
      });
  }, [page]);

  useEffect(() => {
    saveCacheToLocalStorage(cacheKey, { page, sort, filters, columns });
  }, [page, sort, filters, columns]);

  const filtersList = useMemo(
    () => [
      {
        field: 'status',
        locale: 'status',
        optionsForSelect: statusesForSelect.map((opt) => ({ title: opt.name, value: opt.id })),
        type: FilterTypes.GenericMultiSelect,
        active: filters.active.status,
        value: filters.values.status,
        onChange: onChangeFilter('status'),
        onToggle: onToggleFilter('status')
      },
      {
        field: 'active',
        locale: 'active',
        type: 'booleanSelect',
        active: filters.active.active,
        value: filters.values.active,
        onChange: onChangeFilter('active'),
        onToggle: onToggleFilter('active')
      }
    ],
    [filters]
  );

  const columnsWrapped = useMemo(() => columns.map((column) => ALL_COLUMNS_DATA[column]), [columns]);
  const allColumnsWrapped = useMemo(() => ALL_COLUMNS.map((column) => ALL_COLUMNS_DATA[column]), []);

  const onColumnsChange = useCallback(
    (cols) => dispatch({ type: actionTypes.COLUMNS_CHANGED, columns: cols.map((col) => col.id) }),
    []
  );

  const columnContextValue = useMemo(
    () => ({
      onColumnsChange,
      columnsState: columnsWrapped,
      columns: allColumnsWrapped
    }),
    [columnsWrapped, allColumnsWrapped, onColumnsChange]
  );

  const onResetFilters = () => {
    dispatch({ type: actionTypes.FILTER_CHANGED, filters: INITIAL_FILTERS });
  };

  return (
    <ColumnMenuContext.Provider value={columnContextValue}>
      <div className="workspace__body">
        <div className="workspace__major--listing">
          <div className="workspace__header">
            <div className="header__main">
              <h1 className="workspace__title">{translate('title', { namespace })}</h1>
            </div>
          </div>

          <div className="qmb-content-actions">
            <div className="content-actions__group">
              <a className="qmb-control" href={newSubcontractorPath()}>
                <i className="fa-light fa-circle-plus" />
                {translate('new', { namespace })}
              </a>
            </div>
          </div>

          <hr className="workspace__split" />
          <FilterPanel filters={filtersList} onResetFilters={onResetFilters}>
            <div className="filters__group--search">
              <SearchPanel placeholder={I18n.t('filters.search')} onChange={onSearchChange} />
            </div>
          </FilterPanel>
          {loading && (
            <div className="workspace__section">
              <div className="qmb-loading--96" data-title={I18n.t('generic.loading')}>
                <svg role="img">
                  <use href="/map.svg#load-spinner" />
                </svg>
              </div>
            </div>
          )}
          {!loading && (
            <Grid
              className="qmb-grid--01"
              data={data}
              total={totalCount}
              skip={page.skip}
              take={page.take}
              onPageChange={(event) => {
                dispatch({ type: actionTypes.PAGE_CHANGED, page: event.page });
              }}
              pageable={{
                type: 'input',
                pageSizes: [25, 50, 100],
                pageSizeValue: page.take
              }}
              sortable
              sort={sort}
              onSortChange={(event) => {
                dispatch({ type: actionTypes.SORT_CHANGED, sort: event.sort });
              }}>
              {columnsWrapped.map((column) => (
                <Column
                  key={column.id}
                  field={column.field}
                  title={column.title}
                  columnMenu={ColumnMenu}
                  cell={column.cell}
                  sortable={column.sortable}
                  width={column.width}
                />
              ))}
            </Grid>
          )}
        </div>
      </div>
    </ColumnMenuContext.Provider>
  );
}

Index.propTypes = {
  cacheKey: PropTypes.string,
  statusesForSelect: PropTypes.object.isRequired
};

Index.defaultProps = {
  cacheKey: CACHE_KEY
};

export default Index;
