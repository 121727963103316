import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import ArchiveModal from "./ArchiveModal";

const namespace = 'features.settings.tax_items.index';

function ActionButtons({ taxItem, onSwitch, onDuplicate, buttonDuplicated = true, qbEnabled = false }) {

  const archiveAnchor = ({ onClick }) => (
    <button data-allow-click type="button" disabled={qbEnabled} className="qmb-control--icon" onClick={onClick}>
      <i className="fa-light fa-archive" />
    </button>
  );

  return (
    <span>
      {/* DUPLICATE BUTTON - UNCOMMENT IF RE-ADDING
      <button
        type="button"
        className="qmb-control--icon"
        disabled={buttonDuplicated || qbEnabled}
        onClick={onDuplicate}>
        <i className="fa-light fa-copy" />
      </button>
      */}
      {/* ARCHIVE BUTTON - UNCOMMENT IF RE-ADDING
      {taxItem.active && (
        <ArchiveModal
          onConfirm={onSwitch}
          namespace={namespace}
          anchor={archiveAnchor}
        />
      )}
      {!taxItem.active && (
        <button type="button" disabled={qbEnabled} className="qmb-control--icon" onClick={onSwitch}>
          <i className="fa-light fa-arrow-rotate-left" />
        </button>
      )}
      */}
    </span>
  );
}

ActionButtons.propTypes = {
  taxItem: PropTypes.object.isRequired,
  onSwitch: PropTypes.func.isRequired,
  buttonDuplicated: PropTypes.bool.isRequired,
  onDuplicate: PropTypes.func,
  qbEnabled: PropTypes.bool.isRequired
};

ActionButtons.defaultProps = {
  onDuplicate: null
};

export default ActionButtons;
